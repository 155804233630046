import { createContext } from 'react';

const UpdateFilters = createContext({
  updateFilters: false,
  setUpdateFilters: () => {}
});

UpdateFilters.displayName = 'UpdateFilters';

export default UpdateFilters;
