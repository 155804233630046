export const ROOT_URL = process.env.REACT_APP_DOMAIN_URL;

// API URL
export const API_URL = process.env.REACT_APP_API_URL;

// Login
export const LOGIN = `${API_URL}/user/login?_format=json`;

// GET all content
export const EXPORTS = `${API_URL}/rest/solr/exports`;

// RESTf endpoint
const REST_ENDPOINT = `${API_URL}/api/rest-endpoint`;

// GET search filter taxonomy
export const SEARCH_FILTER_TAXONOMY = `${REST_ENDPOINT}/taxonomy`;

// GET nodes
export const NODES = `${API_URL}/jsonapi/node`;

// GET translations
export const TRANSLATIONS = `${REST_ENDPOINT}/node/translations`;

// GET languages
export const LANGUAGES = `${REST_ENDPOINT}/languages`;

// POST favorite
export const POST_FAVORITE_ADD = `${REST_ENDPOINT}/favorite/add`;
export const POST_FAVORITE_REMOVE = `${REST_ENDPOINT}/favorite/delete`;

// GET history
export const GET_HISTORY = `${REST_ENDPOINT}/history`;

// POST history
export const POST_HISTORY = `${REST_ENDPOINT}/history/post`;
