import { faChevronLeft, faChevronRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import styles from './Carousel.module.scss';
import ReactImageMagnify from 'react-image-magnify';
import PDF from './PDF';

const Carousel = (props) => {
  const [imageSource, setImageSource] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    setImageSource([]);
    // eslint-disable-next-line array-callback-return
    if (props.images.length > 0) {
      // eslint-disable-next-line array-callback-return
      props.images?.map(({ attributes }) => {
        if (attributes.filemime === 'image/jpg' || attributes.filemime === 'image/jpeg' || attributes.filemime === 'image/png') {
          fetch(attributes.uri.url)
            .then(({ status }) => {
              if (status === 200) {
                setImageSource((prevState) => [...prevState, attributes.uri.url])
              }
            })
        } else {
          setImageSource((prevState) => [...prevState, attributes.uri.url])
        }
      })
    }
  }, [props.images])

  return (
    <div className='carousel w-8/12'>
      <div className={styles.carousel__preview + ' md:w-10/12'}>
        {
          (imageSource[currentSlide] && !imageSource[currentSlide]?.includes('.pdf'))
            && <ReactImageMagnify {...{
              smallImage: {
                isFluidWidth: true,
                src: imageSource[currentSlide]
              },
              largeImage: {
                src: imageSource[currentSlide],
                width: 1200,
                height: 1800
              },
              shouldUsePositiveSpaceLens: true,
              enlargedImageContainerDimensions: {
                width: '150%',
                height: '100%'
              }
            }} />
        }
      </div>
      {
        !imageSource[currentSlide]?.includes('.pdf') ?
          <span className='block mt-2 mb-4' style={{ fontSize: 13 }}>{imageSource.length > 0 ? 'Hover over asset to zoom in' : 'No assets available'}</span>
          : <span className='block mt-2 mb-4' style={{ fontSize: 13 }}>{window.innerWidth > 1200 ? 'Click' : 'Tap'} on the image below to view at full size.</span>
      }
      <div className='carousel__controls flex items-center md:w-10/12 my-10'>
        {imageSource.length > 0 &&
          <>
            {
              (imageSource.length > 1 && currentSlide !== 0) &&
              <div className='carousel__prev mx-2'>
                <button disabled={currentSlide === 0} onClick={() => {
                  setCurrentSlide((prevState) => prevState - 1)
                }}>
                  <FontAwesomeIcon icon={faChevronLeft} size='lg' color='#555' />
                </button>
              </div>
            }
            <div className={styles.carousel__thumbnail}>
              {currentSlide >= 0 ? (imageSource[currentSlide].includes('.pdf') ? <PDF pdf={imageSource[currentSlide]} pdfLink={imageSource[currentSlide]} /> : <img src={imageSource[currentSlide]} alt='' />) : currentSlide <= 0 ? imageSource[0] : ''}
            </div>
            {
              (imageSource.length > 1 && currentSlide + 1 !== imageSource.length) &&
              <div className='carousel__next mx-2'>
                <button disabled={currentSlide === imageSource.length - 1} onClick={() => {
                  setCurrentSlide((prevState) => prevState + 1);
                }}>
                  <FontAwesomeIcon icon={faChevronRight} size='lg' color='#555' />
                </button>
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

Carousel.defaultProps = {
  images: []
}

export default Carousel;
