import React, { useState, useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toastr from 'toastr';
import Favorite from '../../../components/favorites/Favorite';
import UnFavorite from '../../../components/favorites/UnFavorite';
import Pagination from './Pagination';
import { postFavoriteRemove } from '../../../utils/postFavorite';
import DetailsPanel from '../../../components/details-panel/DetailsPanel';
import styles from '../Favorites.module.scss';
import { faEye } from '@fortawesome/free-regular-svg-icons';

const Table = ({ favoritesList, setUpdateFavorites }) => {
  const [visible, setVisibleDetailsPanel] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [favId, setFavId] = useState('');
  const [id, setId] = useState('');
  const [uuid, setUuid] = useState('');
  const [nid, setNid] = useState('');
  const [language, setLanguage] = useState('');


  const DeleteFavorite = ({ row }) => {

    const handleClick = async () => {
      const res = await postFavoriteRemove(row.original?.fid);
      if (res.status === 200) {
        Toastr.success(await res.text(), null, {
          positionClass: 'toast-bottom-right',
        });
        setUpdateFavorites(true);
      } else if (res.status === 404) {
        Toastr.error(await res.text(), null, {
          positionClass: 'toast-bottom-right',
        });
      }
    }

    return (
      <div className='flex justify-center space-x-2' onClick={handleClick}>
        <Favorite />
      </div>
    )
  }

  const ViewContent = ({ row }) => {
    const handleClick = () => {
      const { fid, id, uuid, nid, langcode } = row.original;
      setIsFavorited(true);
      setFavId(fid);
      setId(id)
      setUuid(uuid);
      setNid(nid);
      setLanguage(langcode);
      setVisibleDetailsPanel(true);
    //   setVisibleDetailsPanel(!visible);
    }

    return (
      <button
        className={[styles.favorites__table__button_style + ' transition-all duration-500 ease-in-out view relative hidden']}
        data-favorited={favoritesList.some(({ id }) => id === row.original.field_id)}
        data-favorite_id={favoritesList.filter(({ id }) => id === row.original.field_id)[0]?.fid}
        data-id={row.original.field_id}
        data-nid={row.original.nid}
        data-uuid={row.original.uuid}
        data-lang={row.original.langcode}
        onClick={handleClick}
        title='View'
        style={{ top: '-2.5px' }}
      >
        <FontAwesomeIcon icon={faEye} size='lg' />
      </button >
    )
  }

  // This component is iterated several times
  const CellWithLink = ({ row, value }) => {
    const handleClick = () => {
      const { id, uuid, nid, langcode } = row.original;
      setIsFavorited(true);
      setFavId(favoritesList.filter(({ id }) => id === row.original.id)[0]?.fid);
      setId(id)
      setUuid(uuid);
      setNid(nid);
      setLanguage(langcode);
      setVisibleDetailsPanel(true);
    //   setVisibleDetailsPanel(!visible);
    }

    return (
      <button
        className={[styles.favorites__table__button_style + ' text-left text-sm mt-2 transition-all duration-500 ease-in-out']}
        data-favorite_id={favoritesList.filter(({ id }) => id === row.original.id)[0]?.fid}
        data-id={row.original.id}
        data-nid={row.original.nid}
        data-uuid={row.original.uuid}
        data-lang={row.original.langcode}
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: value }}
      >
      </button>
    )
  }

  const data = useMemo(
    () => favoritesList, [favoritesList]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Copy',
        accessor: 'copy_block',
        Cell: ({ row, value }) => <CellWithLink row={row} value={value} />
      },
      {
        Header: 'Copy Type',
        accessor: 'copy_type',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Status',
        accessor: 'keywords',
        Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }} />
      },
      {
        Header: 'Date Added',
        accessor: 'date',
        Cell: ({ value }) => new Intl.DateTimeFormat('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        }).format(new Date(parseInt(value) * 1000))
      },
      {
        Header: 'Actions',
        accessor: '',
        Cell: ({ row }) => favoritesList.some(({ id }) => id === row.original.field_id) ?
          <Favorite />
          : <UnFavorite />
      },
      {
        Header: 'Remove',
        Cell: ({ row }) => <DeleteFavorite row={row} />
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const {
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Pass our hoisted table state
    },
    useSortBy,
    usePagination)

  return (
    <div className={styles.favorites__table}>
      {
        page.map((row, index) => {
          prepareRow(row)
          return (
            <div className='flex mb-5 py-4 rounded-xl border border-solid border-gray-200' key={index}
              style={{
                boxShadow: '0 0 10px #bfbfbf',
                paddingLeft: window.innerWidth > 1200 ? 50 : 15,
                paddingRight: window.innerWidth > 1200 ? 50 : 15,
              }}>
              <div className='flex w-full'>
                <div className='w-11/12'>
                  <div className='flex flex-wrap w-full justify-between mb-4'>
                    <div className='flex flex-col'>
                      <p>Copy Type</p>
                      <span className='mt-2 text-sm'>{row.values.copy_type}</span>
                    </div>
                    <div className='flex flex-col'>
                      <p>ID</p>
                      <span className='mt-2 text-sm'>{row.values.id}</span>
                    </div>
                    <div className='flex flex-col xs:w-full md:w-6/12 xs:my-4'>
                      <p>Keywords</p>
                      <div className='flex flex-wrap mt-2'>
                        {
                          row.values.keywords !== undefined && row.values.keywords.split(',').map((text, index) => <span key={index} className={styles.favorites__keywords + ' text-sm py-1 px-4 mr-4 mb-4 rounded-xl border border-solid border-black'} data-bold={text}><a href={`/search-results?search=${text}`}>{text}</a></span>)
                        }
                      </div>
                    </div>
                    <div className='flex flex-col'>
                      <p>Date Added</p>
                      <time className='mt-2 text-sm'>{
                        new Intl.DateTimeFormat('en-US').format(new Date(row.values.date * 1000))
                      }</time>
                    </div>
                  </div>
                  <div className='flex flex-col w-full'>
                    <p>Copy Block</p>
                    <CellWithLink row={row} value={row.values.copy_block} />
                  </div>
                </div>
              </div>
              <div className='flex items-start space-x-2'>
                <DeleteFavorite row={row} />
                <ViewContent row={row} />
              </div>
            </div>
          )
        })
      }
      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        page={page}
        pageOptions={pageOptions}
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalEntries={page.length}
        data={data}
      />
      <DetailsPanel
        favoritesList={favoritesList}
        setIsFavorited={setIsFavorited}
        setFavId={setFavId}
        visible={visible}
        favorited={isFavorited}
        favoriteId={favId}
        id={id}
        nid={nid}
        uuid={uuid}
        language={language}
        setVisibleDetailsPanel={setVisibleDetailsPanel}
        updateResultsTable={setUpdateFavorites}
      />
    </div>
  );
}

Table.defaultProps = {
  data: [],
  columns: []
}

export default Table;
