import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
//import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import styles from './UnFavorite.module.scss';

const UnFavorite = ({ onClick }) => (
  <FontAwesomeIcon 
  icon={faHeart} 
  color='#646464' 
//   icon={faStarRegular} 
//   color='#45A575' 
  size='lg' 
  style={{ cursor: 'pointer' }} 
  onClick={onClick} 
  title='Mark as Favorite' 
  className={styles.heart + ' transition-all duration-500 ease-in-out'} />
)

export default UnFavorite;
