import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import chevronDown from '../../../images/icons/chevron-down.png';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  page,
  pageOptions,
  previousPage,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  totalEntries,
  data,
}) => {
  return (
    <div className='pagination flex flex-wrap justify-between mt-4 w-full'>
      <div className='pagination__showing'>
        <p className='xs:text-sm'>Showing {page.length} of {data.length} entries</p>
      </div>
      <div className='xs:mt-4 pagination__controls flex items-center'>
        <p className='xs:text-sm md:ml-2'>View</p>
        <select
          className='ml-2 mr-10'
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
          style={{
            WebkitAppearance: 'none',
            backgroundImage: `url(${chevronDown})`,
            backgroundColor: '#fff',
            backgroundPosition: '85% center',
            backgroundSize: '.75em',
            backgroundRepeat: 'no-repeat',
            border: '1px solid #bfbfbf',
            borderRadius: '.5em',
            padding: '.25em 0 .25em .5em',
            width: 70,
            cursor: 'pointer'
          }}
        >
          {[5, 10, 20, 25].map(pageSize => (
            <option key={pageSize} value={pageSize} className='xs:text-sm'>
              {pageSize}
            </option>
          ))}
        </select>
        <span className='xs:text-sm mx-2'>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        {/*<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
          */}
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className='px-3 border rounded-l-md py-1' style={{ borderColor: '#bfbfbf' }}>
          <FontAwesomeIcon icon={faCaretLeft} color='#296db0' size='lg' />
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage} className='px-3 border rounded-r-md py-1' style={{ borderColor: '#bfbfbf' }}>
          <FontAwesomeIcon icon={faCaretRight} color='#296db0' size='lg' />
        </button>{' '}
        {/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        */}
      </div>
    </div>
  )
}

export default Pagination;
