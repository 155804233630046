import styles from './Questions.module.scss'
import guidance_icon_white from '../../images/icons/guidance_icon_white.png'

const Questions = () => {
    function open() {
        let element1 = document.getElementById("box1");
        let element2 = document.getElementById("box2");
        let element3 = document.getElementById("box3");
        setTimeout(function(){
            element2.classList.toggle("hidden2")
            element2.classList.toggle("hiding")
            element1.classList.toggle("hidden1")
        }, 100)
        setTimeout(function(){
            element3.classList.toggle("hiding")
        },400)
        setTimeout(function(){
            element3.classList.toggle("gone")
        },600)
        element1.classList.toggle("hiding");
    }
    function close() {
        let element1 = document.getElementById("box1");
        let element2 = document.getElementById("box2");
        let element3 = document.getElementById("box3");
        setTimeout(function(){
            element1.classList.toggle("hidden1")
            element3.classList.toggle("hiding")
            element3.classList.toggle("gone")
        }, 300)
        setTimeout(function(){
            element2.classList.toggle("hiding")
        }, 100)
        element1.classList.toggle("hiding")
        element2.classList.toggle("hidden2");
    }

    return (
    <>
        <div id="box1" className={styles.container} onClick={open}>
            <img src={guidance_icon_white} className={styles.icon} loading='lazy' alt='question mark' />
        </div>
        <div id="box2" className={`${styles.container2} hidden2 hiding`}>
            <div className={styles.top_bar}>
                <div className={styles.close_box} onClick={close}>
                    <hr />
                </div>
            </div>
            <div className={styles.span_text}>
                <div id="box3" className={styles.mask}></div>
                <h3>Questions?</h3>
                <p>Need technical support or help finding something in the Copy Repository?</p>
                <p class="mt-2">Contact us at: <a href="mailto:cr.support@allofuscomms.org" >cr.support@allofuscomms.org</a></p>
                <p></p>
            </div>
        </div>
    </>
    )
}

export default Questions;