import React, { useEffect, useState, useContext, useMemo } from 'react';
import SiteLanguages from '../../contexts/SiteLanguages';
import Header from '../../components/header/Header';
import Filter from '../../components/search/filter/Filter';
import Search from '../../components/search/Search';
import DetailsPanel from '../../components/details-panel/DetailsPanel';
import Footer from '../../components/footer/Footer';
import SearchFilter from '../../contexts/SearchFilter';
import Table from './table/Table';
import getSearchResults from '../../utils/getSearchResults';
import styles from './SearchResults.module.scss';
import { API_URL } from '../../api/endpoints';
import queryString from 'query-string';
import LoadingSVG from '../../images/spinner.svg';
import SearchQuery from '../../contexts/SearchQuery';
import UpdateFilters from '../../contexts/UpdateFilters'; 

const SearchResults = () => {
  const { filters, setFilters } = useContext(SearchFilter);
  const { searchQuery, setSearchQuery } = useContext(SearchQuery);
  const { languages } = useContext(SiteLanguages);
  const { updateFilters, setUpdateFilters } = useContext(UpdateFilters);
  const [tableData, setTableData] = useState({ data: [], isLoading: true });
  const [favoritesList, setFavoritesList] = useState({ data: [], isLoading: true });
  const [visible, setVisibleDetailsPanel] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [favId, setFavId] = useState('');
  const [id, setId] = useState('');
  const [uuid, setUuid] = useState('');
  const [language, setLanguage] = useState('');
  const [nid, setNid] = useState('');
  const [content_type, setContentType] = useState('');
  const [updateResultsTable, setUpdateResultsTable] = useState(true);

  const options = useMemo(() => {
    return {
      data: [
      ],
      done: false,
    };
  }, [])

  let taxonomies = {
    audience: [],
    categories: [],
    copy_type: [],
    proposed_usage: [],
    status: [],
    translations: [],
    langcode: [],
  }

  let resultTaxonomies = {
    audience: [],
    categories: [],
    copy_type: [],
    proposed_usage: [],
    status: [],
    translations: [],
    langcode: [],
  }

  const renderTable = (res) => {
    if (res.length > 0) {
      for (let [index, obj] of Object.entries(res)) {
        for (let [key, value] of Object.entries(obj)) {
          let field = key.slice(6);

          if (['copy_block', 'date_added', 'id', 'tags', 'keywords', 'related_assets', 'source', 'visual_assets', 'search', 'notes', 'conditions', 'keywords_fake', ' '].includes(field) === false) {
            if (field && value) {
              if (field !== 'de' && field !== 'd') {
                resultTaxonomies[field].push(value)
              } else if (field === 'de' && field !== 'd') {
                resultTaxonomies.translations.push(value)
              }
            }
          }

          if (field === 'search') {
            options.data.push({ label: value, value: value });
          }

          if (Number(index) + 1 === res.length) {
            options.done = true;
            setFilters((prevState) => ({
              ...prevState,
              resultTaxonomies,
            }))
          }
        }
      };
      setTableData({ data: res, isLoading: false })
      setSearchQuery((prevState) => ({
        ...prevState,
        isLoading: false,
      }))
    } else {
      setTableData({
        data: [],
        isLoading: false,
      });
      setFilters((prevState) => ({
        ...prevState,
        resultTaxonomies,
      }));
      setSearchQuery((prevState) => ({
        ...prevState,
        isLoading: false,
      }))
    }
  }

  useEffect(() => {
    (async () => {
      // Add "user_id" if it doesn't exist
      if (localStorage.getItem('user_id') === null && localStorage.getItem('okta-token-storage')) {
        try {
          const { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'));
          let res = await fetch(`${API_URL}/api/rest-endpoint/get/user/${idToken?.claims?.email}`);
          let id = await res.json();
          localStorage.setItem('user_id', id);
        } catch (error) {
          throw new Error(error);
        }
      }

      // Get filters if any
      const queryFilters = queryString.parse(window.location.search);

      // Delete the "search=" param
      delete queryFilters.search;

      // Add filter to the context
      for (let [key, value] of Object.entries(queryFilters)) {
        if (value) {
          if (value !== 'langcode') {
            let newValue = value.split(',');
            taxonomies[key].push(encodeURIComponent(newValue[0].replace(/&amp;/g, "&")));
          } else {
            let newValue = value.split(',');
            taxonomies.translations.push(...newValue);
          }
        }
      }

      setFilters((prevState) => ({
        ...prevState,
        taxonomies,
      }))

    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (updateResultsTable) {
      setFavoritesList({ data: [], isLoading: true })
      // Get favorites list
      let userId = localStorage.getItem('user_id');
      fetch(`${API_URL}/api/rest-endpoint/favorite/${userId}/list`)
        .then((res) => res.json())
        .then((json) => {
          setFavoritesList({ data: json, isLoading: false })
          setUpdateResultsTable(false);
        })
        .catch((error) => new Error(error));
    }
  }, [updateResultsTable])

  // This will triger if a filter has changed
  useEffect(() => {
    (async () => {
      const searchQuery = new URLSearchParams(window.location.search).get('search');
      const queryFilters = queryString.stringify(filters.taxonomies, {
        arrayFormat: 'comma',
        skipEmptyString: true,
        strict: false,
        encode: false,
      });

      if (updateFilters) {
        setTableData((prevState) => ({ ...prevState, isLoading: true }))

        try {
          window.history.pushState({}, '', `/search-results?search=${searchQuery.toLowerCase()}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`)
          const res = await getSearchResults(`?search=${encodeURI(searchQuery.replaceAll(/\n|[#_]|[^a-zA-Z0-9]*$/g, '').slice(0, 127).toLowerCase())}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`);
          renderTable(res);
        } catch (error) {
          throw new Error(error)
        }

        setUpdateFilters(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFilters]);

  useEffect(() => {
    (async () => {
      const searchQuery = new URLSearchParams(window.location.search).get('search');
      const filters = queryString.parse(window.location.search);
      delete filters.search;
      const queryFilters = queryString.stringify(filters, {
        arrayFormat: 'comma',
        skipEmptyString: true,
      })

      try {
        window.history.pushState({}, '', `/search-results?search=${searchQuery.replaceAll(/\n|[^a-zA-Z0-9]*$/g, '').toLowerCase()}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`)
        const res = await getSearchResults(`?search=${encodeURI(searchQuery.replaceAll(/\n|[#_]|[^a-zA-Z0-9]*$/g, '').slice(0, 127).toLowerCase())}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`);
        renderTable(res);
      } catch (error) {
        throw new Error(error)
      }

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [pressed, setPressed] = useState(false);

  useEffect(() => {
    window.onpopstate = () => {
      setPressed(true);
    }
  });

  useEffect(() => {
    (async () => {
      if (pressed) {
        setTableData({ data: [], isLoading: true });
        setUpdateResultsTable(true);
        const searchQuery = new URLSearchParams(window.location.search).get('search');
        const filters = queryString.parse(window.location.search);
        delete filters.search;
        const queryFilters = queryString.stringify(filters, {
          arrayFormat: 'comma',
          skipEmptyString: true,
        })
  
        try {
          window.history.pushState({}, '', `/search-results?search=${searchQuery.replaceAll(/\n|[^a-zA-Z0-9]*$/g, '').toLowerCase()}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`)
          const res = await getSearchResults(`?search=${encodeURI(searchQuery.replaceAll(/\n|[#_]|[^a-zA-Z0-9]*$/g, '').slice(0, 127).toLowerCase())}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`);
          renderTable(res);
        } catch (error) {
          throw new Error(error)
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressed]);

  useEffect(() => {
    (async () => {
      if (searchQuery.value) {
        const filters = queryString.parse(window.location.search);
        delete filters.search;
        const queryFilters = queryString.stringify(filters, {
          arrayFormat: 'comma',
          skipEmptyString: true,
        })

        try {
          window.history.pushState({}, '', `/search-results?search=${searchQuery.value}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`)
          const res = await getSearchResults(`?search=${searchQuery.value}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`);
          renderTable(res);
        } catch (error) {
          throw new Error(error)
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery.value])

  // Remove filter
  const handleFilterRemove = (key, encoded) => {
    setFilters((prevState) => ({
      ...prevState,
      taxonomies: {
        ...prevState?.taxonomies,
        [key]: !prevState.taxonomies[key].includes(encoded)
          ? [...prevState.taxonomies[key], encoded]
          : prevState.taxonomies[key].filter(item => item !== encoded)
      },
      selectedFilters: prevState.selectedFilters.filter((obj) => obj.encoded !== encoded)
    }));
    setUpdateFilters(true);
  }

  // Search input
  const handleSubmit = async (e) => {
    e.preventDefault();

    const input = e.target.search.value.toLowerCase();

    if (input) {
      setTableData((prevState) => ({ ...prevState, isLoading: true }));
      setFavoritesList((prevState) => ({ ...prevState, isLoading: true }));
      setFilters({
        taxonomies: {
          audience: [],
          categories: [],
          copy_type: [],
          proposed_usage: [],
          status: [],
          langcode: [],
          translations: [],
        },
        selectedFilters: [],
        selectedLanguages: [],
        resultTaxonomies: {
          audience: [],
          categories: [],
          copy_type: [],
          proposed_usage: [],
          status: [],
          translations: [],
        },
      });

      const queryFilters = queryString.stringify(filters.taxonomies, {
        arrayFormat: 'comma',
        skipEmptyString: true,
      });

      try {
        window.history.pushState({}, '', `/search-results?search=${input}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`)
        const res = await getSearchResults(`?search=${encodeURI(input.replaceAll(/\n|[#_]|[^a-zA-Z0-9]*$/g, '').slice(0, 127).toLowerCase())}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`);
        renderTable(res);
      } catch (error) {
        throw new Error(error)
      }

    }
  }

  ////////////////
  // COMPONENTS //
  //   START    //
  ////////////////

  // Loading
  const Loading = () => (
    <div className='flex items-center justify-center' style={{ minHeight: '100vh' }}>
      <img src={LoadingSVG} alt='loading' />
    </div>
  )

  // Filter taxonomy chips
  const TaxonomyFilterChips = () => {
    return filters.selectedFilters.map(({ key, value, label, encoded }, index) => (
      <div key={index} className="flex justify-center items-center m-1 font-medium py-2 px-3 bg-white rounded-md border border-gray-400 ">
        { key !== 'langcode' ?
          <div className="text-xs font-normal leading-none max-w-full flex-initial">
            <span className='capitalize text-sm' dangerouslySetInnerHTML={{ __html: `${label} (${value})` }}></span>
          </div>
          : (
              <div className="text-xs font-normal leading-none max-w-full flex-initial">
                <span className='capitalize text-sm' dangerouslySetInnerHTML={{ __html: `${languages[label]} (${value})` }}></span>
              </div>
            )
        }
        <div className="flex flex-auto flex-row-reverse">
          <div>
            <svg
              onClick={() => handleFilterRemove(key, encoded)}
              xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x cursor-pointer hover:text-indigo-400 rounded-full w-4 h-4 ml-2">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        </div>
      </div>
    ))
  }

  ////////////////
  // COMPONENTS //
  //    END     //
  ////////////////

  return (
    <>
      <main className={styles.main}>
        <Header />
        <div className={styles.search__results + ' pt-8 pb-8 xs:px-8'}>
          <div className='lg:container mx-auto xs:w-full md:w-full xl:w-8/12'>
            <form onSubmit={handleSubmit} className='xs:w-full md:w-5/12 mx-auto'>
              <Search />
            </form>
          </div>
        </div>
        {
          tableData.isLoading || searchQuery.isLoading
            ? <Loading />
            : <div className='xl:container md:px-12 lg:px-19 lg:mx-auto md:w-full xs:px-8 lg:w-full xl:w-full'>
              <div className='mt-8'>
                {tableData.data.length > 1 ? 
                    new URLSearchParams(window.location.search).get('search').length > 0 ?
                    <p className='xs:text-base text-base text-black'><span className='font-bold text-xl'>{tableData.data.length} results</span> for "{new URLSearchParams(window.location.search).get('search')}"</p>
                    : <p className='xs:text-base text-base text-black'><span className='font-bold text-xl'>{tableData.data.length} results</span> with included filter(s)</p>
                : tableData.data.length === 1 ?
                    new URLSearchParams(window.location.search).get('search').length > 0 ?
                    <p className='xs:text-base text-base text-black'><span className='font-bold text-xl'>{tableData.data.length} result</span> for "{new URLSearchParams(window.location.search).get('search')}"</p>
                    : <p className='xs:text-base text-base text-black'><span className='font-bold text-xl'>{tableData.data.length} result</span> with included filter(s)</p>
                : 
                <>
                    <p className='xs:text-base text-base text-black text-center'><span className='font-bold text-xl'>"{new URLSearchParams(window.location.search).get('search')}"</span></p>
                    <p className='xs:text-base text-base text-black text-center'>We couldn’t find a match for your search.</p>
                    <p className='xs:text-base text-base text-black text-center'>Try searching for other content or adjust the filters.</p>
                </>
                // <>
                //     <p>"{new URLSearchParams(window.location.search).get('search')}"</p>
                //     <p>We couldn’t find a match for your search.</p>
                // </>
                }
              </div>
              {tableData.data.length === 0 ? null :
                <>
                  <div className={styles.search__results__filter + ' mt-4 p-2'}>
                    <Filter />
                  </div>
                  <div className='flex flex-wrap mb-4'>
                    <TaxonomyFilterChips />
                  </div>
                  <div className={styles.search__results__table + ' mb-10'}>
                    <Table
                    tableData={tableData}
                    favoritesList={favoritesList.data}
                    setIsFavorited={setIsFavorited}
                    setFavId={setFavId}
                    favoriteId ={favId}
                    setId={setId}
                    id={id}
                    setUuid={setUuid}
                    setLanguage={setLanguage}
                    setNid={setNid}
                    setContentType={setContentType}
                    setVisibleDetailsPanel={setVisibleDetailsPanel}
                    setUpdateResultsTable={setUpdateResultsTable}
                    visible={visible}
                    />
                  </div>
                </>
                }
            </div>
        }
      </main>
      {
        visible &&
        <DetailsPanel
          favoritesList={favoritesList.data}
          setIsFavorited={setIsFavorited}
          setFavId={setFavId}
          visible={visible}
          contentType={content_type}
          favorited={isFavorited}
          favoriteId={favId}
          id={id}
          nid={nid}
          uuid={uuid}
          language={language}
          setVisibleDetailsPanel={setVisibleDetailsPanel}
          updateResultsTable={setUpdateResultsTable}
        />
      }
      <Footer />
    </>
  )
}

export default SearchResults;
