import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faTimesCircle, faVial } from '@fortawesome/free-solid-svg-icons';
import Carousel from '../../components/carousel/Carousel';
import { API_URL } from '../../api/endpoints';
import getContent from '../../utils/getContent';
import getNodeTranslations from '../../utils/getNodeTranslations';
import postViews from '../../utils/postViews';
import getViews from '../../utils/getViews';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import styles from './SharedContent.module.scss';
import LoadingSVG from '../../images/spinner.svg';
import getLanguages from '../../utils/getLanguages';

const SharedContent = () => {
  const [data, setData] = useState({
    created: new Date(),
    changed: new Date(),
    translations: {},
    categories: [],
    proposed_usage: [],
    audience: [],
    languages: {},
    done: false,
  });

  // Extract queries
  const uuid = new URLSearchParams(window.location.search).get('uuid');
  const nid = new URLSearchParams(window.location.search).get('nid');
  const lang = new URLSearchParams(window.location.search).get('lang');
  const include = 'field_copy_type,field_status,field_categories,field_proposed_usage,field_audience,field_related_asset_id,field_visual_assets.field_media_document,field_visual_assets.field_media_image';

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('user_id') === null && localStorage.getItem('okta-token-storage')) {
        const { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'));
        fetch(`${API_URL}/api/rest-endpoint/get/user/${idToken?.claims?.email}`)
          .then((res) => res.json())
          .then((id) => {
            localStorage.setItem('user_id', id);
          })
          .catch((error) => new Error(error));
      }

      // Get relative data for the panel
      const res = await getContent('copy_block', uuid, include, lang);

      // Get taxonomies
      const [{ attributes }] = res.included.filter(({ type }) => type === 'taxonomy_term--copy_type');
      const status = res.included.filter(({ type }) => type === 'taxonomy_term--status');
      const categories = res.included.filter(({ type }) => type === 'taxonomy_term--categories');
      const proposed_usage = res.included.filter(({ type }) => type === 'taxonomy_term--proposed_usage');
      const audience = res.included.filter(({ type }) => type === 'taxonomy_term--audience');
      const related_assets = res.included.filter(({ type }) => type === 'taxonomy_term--related_asset_ids');
      const images = res.included.filter(({ type }) => type === 'file--file');

      // Get translations
      const translations = await getNodeTranslations(nid);

      // Post view
      const userId = localStorage.getItem('user_id');
      await postViews(nid, userId);
      // Get views
      const view = await getViews(nid);

      // Get site languages
      getLanguages()
        .then((siteLanguages) => {
          setData({
            copy_type: attributes.name,
            id: res.data.attributes.field_id,
            status: status[0].attributes.name,
            conditions: res.data.attributes.field_conditions,
            copy_field_block: res.data.attributes.field_copy_block?.value,
            view_count: view,
            related_assets: related_assets?.map(({ attributes }) => attributes.name),
            related_assets_url: res.data.attributes.field_url,
            created: res.data.attributes.created,
            changed: res.data.attributes.changed,
            defaultLanguage: res.data.attributes.langcode,
            language: res.data.attributes.langcode,
            translations,
            source: res.data.attributes.field_source,
            keywords: res.data.attributes.field_keywords,
            categories: categories.map(({ attributes }) => attributes.name),
            proposed_usage: proposed_usage.map(({ attributes }) => attributes.name),
            audience: audience.map(({ attributes }) => attributes.name),
            docs: images.filter(({ attributes }) => attributes.filename.includes('.pdf' || '.docx' || '.doc')),
            images,
            changelog: res.data.attributes.field_change_log?.value,
            languages: siteLanguages,
            done: true,
          })
        })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeTranslation = async (lang) => {
    try {
      const res = await getContent('copy_block', uuid, include, lang);

      const images = res.included.filter(({ type }) => type === 'file--file');

      setData((prevState) => ({
        ...prevState,
        id: res.data.attributes.field_id,
        language: res.data.attributes.langcode,
        copy_field_block: res.data.attributes.field_copy_block?.value,
        keywords: res.data.attributes.field_keywords,
        images,
        notes: res.data.attributes.field_notes?.value,
        changelog: res.data.attributes.field_change_log?.value,
      }));
    } catch (error) {
      throw new Error(error);
    }
  }

  const StatusField = () => {
    if (data.status === 'IRB Approved No Conditions') {
      return (
        <FontAwesomeIcon className='mr-2'
          icon={faCheck}
          color='#10811e' />
      )
    } else if (data.status === 'IRB Approved With Conditions' || data.status === 'NIH Approved') {
      return (
        <FontAwesomeIcon className='mr-2'
          icon={faExclamationCircle}
          color='#f3a639' />
      )
    } else if (data.status === 'For Test Only') {
        return (
          <FontAwesomeIcon className='mr-2'
            icon={faVial}
            color='#908DC7' />
        )
    } else {
      return (
        <FontAwesomeIcon className='mr-2'
        icon={faTimesCircle}
        color='#cd1a2a' />
      )
    }
  }

  const Loading = () => (
    <div className='flex items-center justify-center' style={{ height: 'calc(100vh - 371px)' }}>
      <img src={LoadingSVG} alt='loading' />
    </div>
  )

  return (
    <>
      <Header />
      <div className={styles.shared__content + ' container mx-auto my-20 xs:my-10'}>
        <div className='container flex flex-wrap justify-evenly mt-8 xs:px-4 mx-auto md:w-8/12 lg:w-full xl:w-8/12'>
          {
            !data.done ? <Loading />
              :
              <>
                <div className='xs:w-full md:w-6/12 pr-4 pl-4'>
                  <div className={styles.shared__content__copy + ' mb-8'}>
                    <h3 className='mb-4 pb-2 border-b xs:text-sm md:text-xl'>Copy</h3>
                    <p dangerouslySetInnerHTML={{ __html: data.copy_field_block }} />
                  </div>
                  <div className={styles.shared__content__sample__assets}>
                    <h3 className='mb-4 pb-2 border-b xs:text-sm md:text-xl'>Visual Reference</h3>
                    <Carousel images={data.images} />
                  </div>
                </div>
                <div className='xs:w-full md:w-6/12 pr-4 pl-4 mb-10'>
                  <div className={styles.shared__content__details + ' break-all'}>
                    <h3 className='xs:mt-4 mb-4 pb-2 border-b xs:text-sm md:text-xl'>Details</h3>
                    <ul style={{ padding: 0 }}>
                      <li className='flex justify-between pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>ID</p>
                        <p className='w-8/12'>{data.id}</p>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-2/12 font-bold'>Status</p>
                        <div className='flex w-8/12 items-center ml-4 xs:pl-6'>
                          <StatusField />
                          <p className='xs:pl-2'>{data.status}</p>
                        </div>
                      </li>
                      {
                        data.conditions &&
                        <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                          <p className='w-4/12 font-bold'>Status Details</p>
                          <p className='w-8/12'>{data.conditions}</p>
                        </li>
                      }
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Source</p>
                        <div className='flex w-8/12'>
                          <a href={data.source} target='_blank' rel='noreferrer noopener'>{data.source}</a>
                        </div>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Language</p>
                        <p className='w-8/12'>{data.languages[data.language]}</p>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Translation(s)</p>
                        <div className='w-8/12'>
                          <ul className='list-disc ml-4 xs:pl-2'>
                            {/* eslint-disable-next-line array-callback-return */}
                            {Object.keys(data?.translations).map((lang, index) => {
                              // If content "Language" is the only translation
                              if (lang === data.language && Object.keys(data?.translations).length === 1) {
                                return (
                                  <li key={index} className='-ml-4 list-none'>
                                    <p>No translation(s)</p>
                                  </li>
                                )
                              }

                              // If translations are available, remove the translation for the content "Language"
                              if (lang !== data.language) {
                                return (
                                  <li key={index} data-lang={lang}>
                                    <button className='cursor-pointer' style={{ color: '#216FB4' }} onClick={() => handleChangeTranslation(lang)}>{data.languages[lang]}</button>
                                  </li>
                                )
                              }
                            })}
                          </ul>
                        </div>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Copy type</p>
                        <p className='w-8/12'>{data.copy_type}</p>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Category</p>
                        <p className='w-8/12'>
                          {data.categories.join(', ')}
                        </p>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Audience</p>
                        <p className='w-8/12'>
                          {data.audience.join(', ')}
                        </p>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Proposed usage</p>
                        <p className='w-8/12'>
                          {data.proposed_usage.join(', ')}
                        </p>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Related assets</p>
                        <div className='flex w-8/12'>
                          {data.related_assets.map((text, index) =>
                            <a
                              key={index}
                              href={data.related_assets_url}
                              className='w-8/12 break-words'
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{ color: '#216FB4' }}>
                              {text}
                            </a>)}
                        </div>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Keywords</p>
                        <div className='flex flex-wrap w-8/12'>
                          {data.keywords?.map((text, index) => <p key={index} className='px-4 py-1 rounded-full border border-gray-400 mr-2 mb-2'>{text}</p>)}
                        </div>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Date added</p>
                        <time className='w-8/12'
                          dateTime={data.created}>{
                            new Intl.DateTimeFormat('en-US', {
                              day: 'numeric',
                              month: 'numeric',
                              year: 'numeric'
                            }).format(new Date(data?.created))
                          }</time>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Last modified</p>
                        <time className='w-8/12'
                          dateTime={data.changed}>{
                            new Intl.DateTimeFormat('en-US', {
                              day: 'numeric',
                              month: 'numeric',
                              year: 'numeric'
                            }).format(new Date(data?.changed))
                          }</time>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Change log</p>
                        <div className='flex w-8/12'>
                          <p className='w-8/15 break-words' dangerouslySetInnerHTML={{ __html: data.changelog }} />
                        </div>
                      </li>
                      <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                        <p className='w-4/12 font-bold'>Notes</p>
                        <div className='flex w-8/12'>
                          <p className='w-8/15 break-words' dangerouslySetInnerHTML={{ __html: data.notes }} />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
          }
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SharedContent;
