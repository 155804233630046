import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import onClickOutside from 'react-onclickoutside';
import { Link } from 'react-router-dom';

const Avatar = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
  });
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  Avatar.handleClickOutside = () => setIsOpen(false);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo({ firstName: '', lastName: '' });
    } else if (!authState.isPending) {
      if (sessionStorage.getItem('profile') === null) {
        const { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'));
        oktaAuth.getUser().then(() => {
          fetch(`${process.env.REACT_APP_OKTA_BASE_URL}/api/v1/users/${idToken.claims.email}`, {
            headers: {
              'Accept': 'application/json',
              'Authorization': `SSWS ${process.env.REACT_APP_OKTA_API_TOKEN}`,
              'Content-Type': 'application/json'
            }
          })
            .then((res) => res.json())
            .then(({ profile }) => {
              setUserInfo(profile);
              sessionStorage.setItem('profile', JSON.stringify({ firstName: profile.firstName, lastName: profile.lastName, email: profile.email }))
            })
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tailwindClass = 'origin-top-right absolute right-0 w-60 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5';
  const profile = JSON.parse(sessionStorage.getItem('profile'));

  const nameToInitials = (fullName) => {
    if (fullName === 'undefined undefined') {
      return;
    }
    let initials = '';
    if (!fullName) return initials;
    const splitName = fullName.trim().split(' ');
  
    if (splitName.length > 1) {
      initials = splitName[0][0] + splitName[splitName.length - 1][0];
    } else {
      initials = splitName[0][0];
    }
  
    return initials.toUpperCase();
  }

  return (
    <div className='mt-2 mb-2'>
      <div className="relative inline-block text-left">
        <div>
          <button type="button"
            onClick={toggle}
            className="inline-flex justify-center w-full rounded-md bg-white text-sm font-medium text-gray-700"
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            style={{ width: 56, height: 56, backgroundColor: '#7ac79b', borderRadius: '1000px', }}
          >
            <div
              style={{
                transform: 'translate(0, 1em)',
              }}>
              <span className='text-white text-lg'>{userInfo?.firstName ? nameToInitials(`${userInfo.firstName} ${userInfo.lastName}`) : nameToInitials(`${profile?.firstName} ${profile?.lastName}`)}</span>
            </div>
          </button>
        </div>
        <div className={isOpen ? `visible ${tailwindClass}` : `hidden ${tailwindClass}`}>
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div className='flex flex-col px-4 py-2 border-b-2'>
              <span className='text-sm text-gray-600'>Signed in as</span>
              <span className='text-sm break-all'>{userInfo?.email ? userInfo?.email : profile?.email}</span>
            </div>
            <div className='flex flex-col px-4 py-2 border-b-2'>
              <span className='text-sm text-gray-600'>Full name</span>
              <span className='text-sm break-all'>{userInfo?.firstName ? `${userInfo?.firstName} ${userInfo.lastName}` : `${profile?.firstName} ${profile?.lastName}`}</span>
            </div>
            {
              window.innerWidth < 640 &&
              <>
                <div className='flex flex-col px-4 py-2 border-b-2'>
                  <Link to='/get-started' className='text-sm' style={{ color: '#000' }}>Get Started</Link>
                </div>
                <div className='flex flex-col px-4 py-2 border-b-2'>
                  <Link to='/favorites' className='text-sm' style={{ color: '#000' }}>My Favorites</Link>
                </div>
                <div className='flex flex-col px-4 py-2 border-b-2'>
                  <a href='//allofuscomms.org' className='text-sm' style={{ color: '#000' }}>Comms Portal</a>
                </div>
              </>
            }
            <button 
            onClick={() => {
              oktaAuth.tokenManager.clear();
              oktaAuth.signOut();
            }} 
            className='w-full text-left text-sm px-4 py-2 hover:bg-gray-100'>Sign out</button>
          </div>
        </div>
      </div>
    </div>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => Avatar.handleClickOutside
};

Avatar.prototype = {};

export default onClickOutside(Avatar, clickOutsideConfig);
