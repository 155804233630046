import { createContext } from 'react';

const SearchOptions = createContext({
  optionsVisible: false,
  setOptionsVisible: () => {}
});

SearchOptions.displayName = 'SearchOptions';

export default SearchOptions;
