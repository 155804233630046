import { POST_HISTORY } from '../api/endpoints';

/**
 * Adds a view count to the content
 * @param {string} nid - Node ID
 * @param {string} uid - User ID
 */
const postViews = async (nid, uid) => {
  const { csrf_token } = JSON.parse(localStorage.getItem('login_response'));

  try {
    const res = await fetch(`${POST_HISTORY}/${nid}/${uid}`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrf_token,
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': `allow-from ${process.env.REACT_APP_DOMAIN_URL}`,
        'Content-Type': 'application/json',
      },
    });
    const json = await res.json();

    return json;
  } catch (error) {
    throw new Error(error);
  }

}

export default postViews;
