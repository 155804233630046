import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import queryString from 'query-string';
import SearchFilter from '../../contexts/SearchFilter';
import SearchQuery from '../../contexts/SearchQuery';
import { useHistory } from 'react-router-dom';
import { EXPORTS } from '../../api/endpoints';
import styles from './Search.module.scss';
import useOutsideClick from './useOutsideClick';
import VerticalLoading from '../../images/vertical_loading.svg';
import SearchOptions from '../../contexts/SearchOptions';

const Search = (props) => {
  const { filters, setFilters } = useContext(SearchFilter);
  const { setSearchQuery } = useContext(SearchQuery);
  const { optionsVisible, setOptionsVisible } = useContext(SearchOptions);
  const [fetchingData, setFetchingData] = useState(false);
  const [currentTyping, setCurrentTyping] = useState('');
  const [resultsList, setReslutsList] = useState({ data: [], done: false });
  const history = useHistory();
  const inputRef = useRef(null);
  const inputParent = useRef(null);

  const handleChange = (e) => {
    setFilters({
        taxonomies: {
          audience: [],
          categories: [],
          copy_type: [],
          proposed_usage: [],
          status: [],
          langcode: [],
          translations: [],
        },
        selectedFilters: [],
        selectedLanguages: [],
        resultTaxonomies: {
          audience: [],
          categories: [],
          copy_type: [],
          proposed_usage: [],
          status: [],
          translations: [],
        },
        reference: '',
      });
    setCurrentTyping(e.target.value);
    if (e.target.value.length >= 3) {
      setOptionsVisible(true);
    }
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setOptionsVisible(false);
    }
  }

  let resultTaxonomies = {
    audience: [],
    categories: [],
    copy_type: [],
    proposed_usage: [],
    status: [],
    translations: [],
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (currentTyping.length >= 3) {
        setFetchingData(true);
        fetch(`${EXPORTS}?search=${currentTyping.replace(/^\d+|[#_]+/g, ' ').replace(/\?/g,' ').replace(/\¿/g,' ')}`)
          .then((res) => res.json())
          .then((json) => {
            if (json.length > 0) {
              
              setReslutsList({ data: [], done: false });
              
              if (window.location.pathname !== '/search-results') {
                for (let [index, obj] of Object.entries(json)) {

                  setReslutsList((prevState) => ({
                    ...prevState,
                    data: [...prevState.data, { label: obj.field_copy_block, value: obj.field_search }]
                  }))

                  for (let [key, value] of Object.entries(obj)) {
                    let field = key.slice(6);

                    if (['copy_block', 'date_added', 'id', 'tags', 'keywords', 'related_assets', 'source', 'visual_assets', 'search', 'notes', 'conditions', ' '].includes(field) === false) {
                      if (field && value) {
                        if (field !== 'de' && field !== 'd') {
                          resultTaxonomies[field].push(value)
                        } else if (field === 'de' && field !== 'd') {
                          resultTaxonomies.translations.push(value)
                        }
                      }
                    }

                    if (Number(index) + 1 === json.length) {
                      setReslutsList((prevState) => ({
                        ...prevState,
                        done: true,
                      }))
                      setFetchingData(false);
                      setFilters((prevState) => ({
                        ...prevState,
                        resultTaxonomies,
                      }))
                    }
                  }
                }
              } else {
                json.forEach(({ field_copy_block, field_search, field_source, field_id }, index) => {
                  setReslutsList((prevState) => ({
                    ...prevState,
                    data: [...prevState.data, { label: field_copy_block, value: field_search, source: field_source, id: field_id, }]
                  }))

                  if (json.length === index + 1) {
                    setReslutsList((prevState) => ({
                      ...prevState,
                      done: true,
                    }))
                    setFetchingData(false);
                  }
                })
              }
            } else {
              setReslutsList((prevState) => ({
                ...prevState,
                done: true,
              }))
              setFetchingData(false);
            }
          })
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTyping])

  const Suggestions = () => {

    const handleClick = (value) => {
      setFilters({
        taxonomies: {
          audience: [],
          categories: [],
          copy_type: [],
          proposed_usage: [],
          status: [],
          langcode: [],
          translations: [],
        },
        selectedFilters: [],
        selectedLanguages: [],
        resultTaxonomies: {
          audience: [],
          categories: [],
          copy_type: [],
          proposed_usage: [],
          status: [],
          translations: [],
        },
        reference: '',
      });
      const { pathname } = window.location;
      if (pathname === '/home') {
        // Construct the query
        const queryFilters = queryString.stringify(filters.taxonomies, {
          arrayFormat: 'comma',
          skipEmptyString: true,
        });
        if (value) {

         var startingString = value;
          var maxLength = 122; 
          var trimmedString = startingString.substr(0, maxLength);

          trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));

          history.push(`/search-results?search=${trimmedString.replaceAll(/\n|[^a-zA-Z0-9]*$/g, ' ').toLowerCase()}${queryFilters ? '&' + queryFilters : ''}`);
        }
      } else if (pathname === '/favorites') {
        if (value) {
          history.push(`/search-results?search=${value.replaceAll(/\n|[^a-zA-Z0-9]*$/g, ' ').toLowerCase()}`);
        }
      } else {

       var startingString = value;
          var maxLength = 122; 
          var trimmedString = startingString.substr(0, maxLength);

          trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
          
        const queryFilters = queryString.stringify(filters.taxonomies, {
          arrayFormat: 'comma',
          skipEmptyString: true,
        });

        window.history.pushState({}, '', `/search-results?search=${trimmedString}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`)
        setSearchQuery({ label: encodeURI(value.replaceAll(/\n|[#_]|[^a-zA-Z0-9]*$/g, ' ').slice(0, 122).toLowerCase()), value: encodeURI(trimmedString.replaceAll(/\n|[#_]|[^a-zA-Z0-9]*$/g, ' ').slice(0, 122).toLowerCase()), isLoading: true});
      }
      setCurrentTyping(trimmedString.replace(/^\d+|[#_]+/g, ' ').replace(/\?/g,' ').replace(/\¿/g,' '));
      setOptionsVisible(false);
    };

    return useMemo(() => {
      if (fetchingData) {
        return (
          <div className='flex items-center justify-center w-full py-4 border border-gray-300 border-solid'>
            <span>Fetching content for <b>{currentTyping}</b></span>
            <img src={VerticalLoading} alt='vertical loading' className='w-12 ml-2' />
          </div>
        )
      }

      if (resultsList.done && resultsList.data.length === 0) {
        return (
          <div className='flex items-center justify-center w-full py-4 border border-gray-300 border-solid'>
            <span>No results found for <b>{currentTyping}</b></span>
          </div>
        )
      }

      return resultsList.data.map(({ label, value }, index) => (
        <li key={index} className='p-4 w-full cursor-pointer hover:bg-gray-100 drop_down_results_list' onClick={() => handleClick(value)}
          dangerouslySetInnerHTML={{ __html: label }}>
        </li>
      ))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  }

  useOutsideClick(inputRef, () => {
    setOptionsVisible(false);
  })

  return (
    <>
      <div className={`${styles.search__input} flex items-center px-4 ${props.width}`} ref={inputParent}>
        <input
          value={currentTyping}
          autoComplete='off'
          type='text'
          name='search'
          ref={inputRef}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          style={{ padding: '.5em 0', width: '100%', appearance: 'none', background: 'transparent' }}
          placeholder={props.placeholder}
        />
        <button type='button' onClick={(e) => {
          const inputValue = inputRef.current.value.toLowerCase();
          // Construct the query
          const queryFilters = queryString.stringify(filters.taxonomies, {
            arrayFormat: 'comma',
            skipEmptyString: true,
          });

          const { pathname } = window.location;
          if (pathname === '/home') {
            // Construct the query
            const queryFilters = queryString.stringify(filters.taxonomies, {
              arrayFormat: 'comma',
              skipEmptyString: true,
            });

            if (inputValue) {
              setSearchQuery({ label: encodeURI(inputValue.toLowerCase()), value: encodeURI(inputValue.toLowerCase()), update: false, })
              history.push(`/search-results?search=${inputValue}${queryFilters ? '&' + queryFilters : ''}`);
            }
          } else if (pathname === '/favorites') {
            if (inputValue) {
              setSearchQuery({ label: encodeURI(inputValue.toLowerCase()), value: encodeURI(inputValue.toLowerCase()), update: false, })
              history.push(`/search-results?search=${inputValue}${queryFilters ? '&' + queryFilters : ''}`);
            }
          } else {
            const queryFilters = queryString.stringify(filters.taxonomies, {
              arrayFormat: 'comma',
              skipEmptyString: true,
            });

            window.history.pushState({}, '', `/search-results?search=${inputValue.toLowerCase()}${queryFilters ? '&' + queryFilters.toLowerCase() : ''}`)
            setSearchQuery({ label: encodeURI(inputValue.toLowerCase()), value: encodeURI(inputValue.toLowerCase()), update: true, });
          }
        }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#9c9c9c" className="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
        </button>
      </div>
      <div className={optionsVisible ? 'visible absolute' : 'hidden'} style={{ width: inputParent.current !== null ? inputParent.current.clientWidth : 0 }}>
        <ul style={{ maxHeight: '20em' }} className='flex flex-wrap overflow-auto bg-white shadow-lg pl-0'>
          <Suggestions />
        </ul>
      </div>
    </>
  )
}

Search.defaultProps = {
  width: 'w-full',
  placeholder: 'Search Copy Repository',
}

export default Search;
