import { createContext } from 'react';

const SiteLanguages = createContext({
  languages: {},
  setLanguages: () => {}
});

SiteLanguages.displayName = 'SiteLanguages';

export default SiteLanguages;
