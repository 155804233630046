import React, { useEffect } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import SearchComponent from './search/SearchComponent';
import { API_URL } from '../../api/endpoints';
import styles from './Home.module.scss';
import { useOktaAuth } from '@okta/okta-react';
import LoadingSVG from '../../images/spinner.svg';

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      //setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        //setUserInfo(info);
      });
    }

    if (
      localStorage.getItem('user_id') === null &&
      localStorage.getItem('okta-token-storage')
    ) {
      const { idToken } = JSON.parse(
        localStorage.getItem('okta-token-storage')
      );
      fetch(`${API_URL}/api/rest-endpoint/get/user/${idToken?.claims?.email}`)
        .then((res) => res.json())
        .then((id) => {
          localStorage.setItem('user_id', id);
        })
        .catch((error) => new Error(error));
    }
    // eslint-disable-next-line
  }, []);

  if (authState.isPending) {
    return (
      <div className="flex items-center justify-center h-screen">
        <img src={LoadingSVG} alt="loading" />
      </div>
    )
  }

  if (!authState.isAuthenticated && !authState.isPending) {
    const tbr = <></>;

    oktaAuth.signInWithRedirect();

    return tbr;
  } else {
    const tbr = (
      <>
        <Header />
        <main className={styles.home}>
          <div className="container mx-auto xs:px-8 md:w-8/12 lg:w-full xl:w-8/12">
            <div className="search__title xs:my-6 md:mt-20 mb-6">
              <h3 className="text-center xs:text-lg md:text-2xl">
                The <i>All of Us</i> Research Program{' '}
                <span>Copy Repository</span>
              </h3>
            </div>
            <SearchComponent />
          </div>
        </main>
        <Footer />
      </>
    );

    return tbr;
  }
};

export default Home;
