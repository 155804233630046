import React, { useState, useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { faCheck, faExclamationCircle, faTimesCircle, faVial, faWrench, faLaptop } from '@fortawesome/free-solid-svg-icons';
import Toastr from 'toastr';
import Pagination from './Pagination';
import Favorite from '../../../components/favorites/Favorite';
import UnFavorite from '../../../components/favorites/UnFavorite';
import sortAsc from '../../../images/icons/chevron-expand-asc.svg';
import sortDesc from '../../../images/icons/chevron-expand-desc.svg';
import sortDefault from '../../../images/icons/chevron-expand-default.svg';
import Blocks from '../../../images/spinner.svg';
import { postFavoriteAdd, postFavoriteRemove } from '../../../utils/postFavorite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

const Table = ({
  tableData,
  favoritesList,
  setIsFavorited,
  setFavId,
  favoriteId,
  setId,
  id,
  setUuid,
  setLanguage,
  setNid,
  setVisibleDetailsPanel,
  setUpdateResultsTable,
  visible,
}) => {
  const [componentFavoriteId, setComponentFavoriteId] = useState(0);

  async function handleFavorites(state, row) {
    const { nid, field_id, langcode } = row.original;
    
    try {
      if (state) 
      {
        const [{ fid }] = favoritesList.filter(({ id }) => id === row.original.field_id)
        const res = await postFavoriteRemove(fid !== undefined ? fid : componentFavoriteId);
        //console.log(res);
        if (res.status === 200) {
          Toastr.success(await res.text(), null, {
            positionClass: 'toast-bottom-right',
          });
          setUpdateResultsTable(true);
        }
      } else 
      {
        const res = await postFavoriteAdd(nid, langcode);
        //console.log(res);
        if (res.status === 200) {
          const { id, type, message } = await res.json();
          //console.log(type);
          //console.log(id);
          //console.log(message);
          if (type === 'success') {
            Toastr.success(message, null, {
              positionClass: 'toast-bottom-right',
            });
            setComponentFavoriteId(id);
            setUpdateResultsTable(true);
          } else {
            Toastr.error(message, null, {
              positionClass: 'toast-bottom-right',
            });
          }
        }
      }
    } catch (error) {
      Toastr.error(error.message, null, {
        positionClass: 'toast-bottom-right',
      })
      throw new Error(error);
    }
  }

  const Actions = ({ row }) => {
    if (favoritesList.some(({ id }) => id === row.original.field_id)) {
      return (
        <div className='flex justify-end items-center space-x-2'>
          <Favorite onClick={() => handleFavorites(true, row)}/>
          <ViewContent row={row} />
        </div>
      )
    }
    return <div className='flex justify-end items-center space-x-2'>
      <UnFavorite onClick={() => handleFavorites(false, row)}/>
      <ViewContent row={row} />
    </div>
  }

  

  const data = useMemo(
    () => {
        let data = tableData.data
        const monthNames = ["Jan", "Feb", "Mar", "Apr",
                        "May", "Jun", "Jul", "Aug",
                        "Sep", "Oct", "Nov", "Dec"];
        data.forEach(item => {
            let date = new Date(item.field_date_added)
            // : item.field_date_added = date.toDateString()
            // item.field_date_added = date.toDateString().slice(4)
            item.field_date_added = Date.parse(date)
            date = Date.parse(date)
            // console.log("DATE: ", date)
            // item.field_date_added != NaN ? item.field_date_added = date.toDateString().slice(4) : item.field_date_added

            let dateChanged = new Date(item.changed)
            item.changed = Date.parse(dateChanged)
            dateChanged = Date.parse(dateChanged)
        })
        // console.log(tableData.data)
        return tableData.data
    },[tableData.data]
  )

  const statusSortFactory = () => {
    const statusValues = { "IRB Approved No Conditions": 0, "IRB Approved With Conditions": 1, "NIH Approved": 2, "For Researchers Only": 3, "Prohibited": 4, "For Test Only": 5 };
    const sortFunc = (a, b) => {
        // console.log("a: ",a.values.field_status)
      const aStatus = a.values.field_status;
      const bStatus = b.values.field_status;
      const aVal = statusValues[aStatus];
      const bVal = statusValues[bStatus];
      if (aVal === bVal) {
        return 0
      } else {
      return aVal < bVal ? -1 : 1;
    }
    };
    return sortFunc;
  };
  
  const statusSort = useMemo(statusSortFactory);


  const columns = useMemo(
    () => [
    //   {
    //     Header: 'Copy',
    //     accessor: 'field_copy_block',
    //     Cell: ({ row, value }) => <CellWithLink row={row} value={value} />
    //   },
      {
        Header: 'Copy',
        accessor: 'field_search',
        Cell: ({ row }) => <CellWithLink row={row} value={row.original.field_copy_block} />
      },
      {
        Header: 'Copy Type',
        accessor: 'field_copy_type',
        Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: value }} />
      },
      {
        Header: 'ID',
        accessor: 'field_id',
      },
      {
        Header: 'Status',
        accessor: 'field_status',
        sortType: statusSort,
        Cell: ({ row }) => {

          const StatusFieldIcons = ({row}) => {
            if (row.original.field_status === 'IRB Approved No Conditions') {
              return (
                <FontAwesomeIcon className='mr-2'
                  icon={faCheck}
                  color='#10811e' />
              )
            } else if (row.original.field_status === 'IRB Approved With Conditions' || row.original.field_status === 'NIH Approved') {
              return (
                <FontAwesomeIcon className='mr-2'
                  icon={faExclamationCircle}
                  color='#f3a639' />
              )
            } else if (row.original.field_status === 'For Test Only') {
              return (
                <FontAwesomeIcon className='mr-2'
                  icon={faWrench}
                  color='#a27db7' />
              )
            } else if (row.original.field_status === 'For Researchers Only') {
              return (
                <FontAwesomeIcon className='mr-2'
                  icon={faLaptop}
                  color='#5eaee0' />
              )
            } else {
              return (
                <FontAwesomeIcon className='mr-2'
                icon={faTimesCircle}
                color='#cd1a2a' />
              )
            }
          }        

          const html = row.original.field_status === 'For Researchers Only' ?
                    ` 
                        <b>${row.original.field_status}</b>
                        ${row.original.field_conditions &&
                        `<p style="margin-top: 1em;"><b>Status Details:</b></p> 
                        <p>${row.original.field_conditions}</p>`
                        // `<p style="margin-top: 1em;"><b>Status Details:</b></p> 
                        // <p style="color:#6baae2">${row.original.field_conditions}</p>`
                        }
                        ${row.original.field_notes &&
                        `<p style="margin-top: 1em;"><b>Notes:</b></p> 
                        ${row.original.field_notes}`
                        }
                    `
                    :
                    ` 
                        <b>${row.original.field_status}</b>
                        ${row.original.field_conditions &&
                        `<p style="margin-top: 1em;"><b>Status Details:</b></p> 
                        <p>${row.original.field_conditions}</p>`
                        }
                        ${row.original.field_notes &&
                        `<p style="margin-top: 1em;"><b>Notes:</b></p> 
                        ${row.original.field_notes}`
                        }
                    `

          return (
          <div>
            <StatusFieldIcons row ={row}/>
            <span dangerouslySetInnerHTML={{ __html: html }} />
          </div>
          )
        }
      },
      {
        Header: 'LAST MODIFIED',
        accessor: 'changed',
        id:'date_id',
        desc: true,
        Cell: ({ value }) => <span dangerouslySetInnerHTML={{ __html: new Date(value).toDateString() === "Invalid Date" 
        ? "Invalid" : (new Date(value).getMonth() + 1) + '/' + new Date(value).getDate() + '/' + new Date(value).getFullYear().toString().slice(-2) }} />
        // ? new Date(value).toDateString() : new Date(value).toDateString().slice(4) }} />
      },
      {
        Header: 'Actions',
        accessor: '',
        Cell: ({ row }) => <Actions row={row} />
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [favoritesList]
  )

  const ViewContent = ({ row }) => {
    const handleClick = () => {
      const { field_id, nid, uuid, langcode } = row.original;
      //console.log(row.original);
      setIsFavorited(favoritesList.some(({ id }) => id === row.original.field_id));
      setFavId(favoritesList.filter(({ id }) => id === row.original.field_id)[0]?.fid);
      setId(field_id)
      setUuid(uuid);
      setLanguage(langcode);
      setNid(nid);
      setVisibleDetailsPanel(true);
    //   setVisibleDetailsPanel(!visible);
      //console.log(visible);
      
    }

    return (
      <button
        data-favorited={favoritesList.some(({ id }) => id === row.original.field_id)}
        data-favorite_id={favoritesList.filter(({ id }) => id === row.original.field_id)[0]?.fid}
        data-id={row.original.field_id}
        data-nid={row.original.nid}
        data-uuid={row.original.uuid}
        data-lang={row.original.langcode}
        onClick={handleClick}
        title='View'
      >
        <FontAwesomeIcon icon={faEye} color="#646464" size="lg" className="view transition-all duration-500 ease-in-out hidden" />
      </button >
    )
  }

  // This component is iterated several times
  const CellWithLink = ({ row, value }) => {
    const handleClick = () => {
      const { field_id, nid, uuid, langcode } = row.original;
      setIsFavorited(favoritesList.some(({ id }) => id === row.original.field_id));
      setFavId(favoritesList.filter(({ id }) => id === row.original.field_id)[0]?.fid);
      setId(field_id)
      setUuid(uuid);
      setLanguage(langcode);
      setNid(nid);
      setVisibleDetailsPanel(true);
    //   setVisibleDetailsPanel(!visible);
    }

    return (
      <button
        className='text-left transition-all duration-500 ease-in-out favorite'
        data-favorited={favoritesList.some(({ id }) => id === row.original.field_id)}
        data-favorite_id={favoritesList.filter(({ id }) => id === row.original.field_id)[0]?.fid}
        data-id={row.original.field_id}
        data-nid={row.original.nid}
        data-uuid={row.original.uuid}
        data-lang={row.original.langcode}
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: value }}
        style={{ wordBreak: 'break-word' }}
      >
      </button>
    )

  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      disableMultiRemove: true,
    //   initialState: { pageIndex: 0, pageSize: 10, sortBy: [{ id: 'date_id', desc: true }] }, // Pass our hoisted table state,
      initialState: { pageIndex: 0, pageSize: 10, sortBy: [{ id: 'field_status', desc: false }, { id: 'date_id', desc: true}] }, // Pass our hoisted table state,
    //   initialState: { pageIndex: 0, pageSize: 10, sortBy: [{ id: 'date_id', desc: true},{ id: 'field_status', desc: false }] }, // Pass our hoisted table state,
    },
    useSortBy,
    usePagination)

  if (tableData.isLoading) {
    return (
      <div className='flex items-center justify-center' style={{ minHeight: 'calc(100vh - 158px - 80px)' }}>
        <img src={Blocks} alt='loading' />
      </div>
    )
  }

  if (tableData.data.length === 0 && !tableData.isLoading) {
    // return (
    //   <h1 className='xs:text-base text-2xl mt-2'>No results are available for <span className='border-b border-solid border-black pb-1'>{new URLSearchParams(window.location.search).get('search').charAt(0).toUpperCase() + new URLSearchParams(window.location.search).get('search').slice(1)}</span>.
    //     Try searching for other content or adjust the filters.
    //   </h1>
    // )
  }

  if (tableData.data.length !== 0 && !tableData.isLoading) {
  return (
    <>
      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        page={page}
        pageOptions={pageOptions}
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalEntries={page.length}
        data={data}
      />
      <table {...getTableProps()} style={{ border: '1px solid #d6d6d6' }} className='w-full'>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    borderBottom: '1px solid #979797',
                    borderTop: '1px solid #979797',
                    background: '#f1f2f2',
                    color: 'black',
                    fontWeight: 'bold',
                    padding: 10,
                    textAlign: 'left',
                  }}
                >
                  <div className='flex items-center cursor-pointer'>
                    {column.render('Header')}
                    <span className='w-full ml-2'>
                        {/* {console.log("Column: ", column)} */}
                      { column.isSorted ? 
                        (column.isSortedDesc ? <img src={sortDesc} loading='lazy' alt='sort desc' /> : <img src={sortAsc} loading='lazy' alt='sort asc' />) 
                        : <img src={sortDefault} loading='lazy' alt='sort default' /> }
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className='flex-1 sm:flex-none'>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  if(cell.row.original.field_status === "For Researchers Only"){
                    // console.log(cell.column["Header"] === "Status")
                    return <td {...cell.getCellProps()} data-label={cell.column.Header} style={{ backgroundColor: "#6baae220", padding: 10, verticalAlign: 'top' }}>{cell.render('Cell')}</td>

                    // if(cell.column["Header"] === "Copy") {
                    //     return <td {...cell.getCellProps()} data-label={cell.column.Header} style={{ backgroundColor: "#6baae220", padding: 10, verticalAlign: 'top' }}>{cell.render('Cell')}</td>
                    // } else if(cell.column["Header"] === "Status") {
                    //     return <td {...cell.getCellProps()} data-label={cell.column.Header} style={{ backgroundColor: "#6baae220", padding: 10, verticalAlign: 'top' }}>{cell.render('Cell')}</td>
                    // } else {
                    //     return <td {...cell.getCellProps()} data-label={cell.column.Header} style={{ padding: 10, verticalAlign: 'top' }}>{cell.render('Cell')}</td>
                    // }

                } else {
                  return <td {...cell.getCellProps()} data-label={cell.column.Header} style={{ padding: 10, verticalAlign: 'top' }}>{cell.render('Cell')}</td>
                }
            })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        page={page}
        pageOptions={pageOptions}
        previousPage={previousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalEntries={page.length}
        data={data}
      />
      </>
    )
  } else {
    return null
  }
}

Table.defaultProps = {
  data: [],
  columns: []
}

export default Table;
