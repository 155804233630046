import { EXPORTS } from '../api/endpoints';

/**
 * Returns the search results
 * @param {string} query - query params 
 */
const getSearchResults = async (query) => {
  const { csrf_token } = JSON.parse(localStorage.getItem('login_response'));

  try {
    const res = await fetch(EXPORTS.concat(query), {
      headers: {
        'X-CSRF-Token': csrf_token,
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': `allow-from ${process.env.REACT_APP_DOMAIN_URL}`,
        'Content-Type': 'application/json',
      },
    });
    const json = await res.json();

    return json;
  } catch (error) {
    throw new Error(error);
  }

}

export default getSearchResults;
