import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import Search from '../../../components/search/Search';
import SearchFilter from '../../../contexts/SearchFilter';

const SearchComponent = () => {
  const { filters } = useContext(SearchFilter);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const input = e.target.search.value.toLowerCase();

    if (input) {
      // Construct the query
      const queryFilters = queryString.stringify(filters.taxonomies, {
        arrayFormat: 'comma',
        skipEmptyString: true,
      }).toLowerCase();

      history.push(`/search-results?search=${input}${queryFilters ? '&' + queryFilters : ''}`);
    }
  }

  return (
    <form onSubmit={handleSubmit} className='mx-auto'>
      <div className='w-5/12 mx-auto xs:w-full'>
        <Search />
      </div>
    </form>
  )
}

export default SearchComponent;
