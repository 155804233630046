import React from 'react';
import HeroImage from '../../../images/AP_3_Login.jpg';

const Hero = () => (
  <div className='hero flex flex-wrap justify-between items-center xs:my-6 md:mt-12 mb-12'>
    <section className='flex flex-col xs:w-full md:w-5/12'>
      <div className='hero__heading mb-6'>
        <h2 className='font-bold xs:text-lg md:text-4xl' style={{ color: '#262262' }}>Welcome to the <i>All of Us</i> Research Copy Repository</h2>
      </div>
      <div className='hero__text'>
        <p className='xs:text-sm md:text-lg'>Our copy managament system has several
        features designed to make your experiences as seamless as possible. Some of
        these features include X, Y and Z. To start, we invite all partners
        to read our <a href='//allofus.nih.gov/about' target='_blank' rel='noreferrer noopener' style={{ color: '#216fb4' }}>Welcome Guide</a>.
      </p>
      </div>
    </section>
    <div className='hero__img w-4/12 xs:hidden md:visible'>
      <img src={HeroImage} loading='lazy' alt='All of Us avatars' />
    </div>
  </div>
)

export default Hero;
