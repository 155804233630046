import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import chevronDown from '../../../images/icons/chevron-down.png';
import { useEffect, useState } from 'react';

const Pagination = ({
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  page,
  pageOptions,
  previousPage,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
  totalEntries,
  data,
}) => {

//state management for current page value and respective controlled form component
//const [pagecurrval, setPagecurrval] = useState(0);

//useEffect to scroll to top of window when page changes
useEffect(()=> {
  window.scrollTo(0,0);
},[page]);

//event handle function/routine for scrolling up when user hits enter after typing number pagination controls
const evnthndleEnterScrollUp =(e)=>{
  if(e.key === 'Enter')
  {
      window.scrollTo(0,0);
  }
}


  return (
    <div className='pagination flex flex-wrap justify-between mt-4 w-full' style={{paddingBottom:'1.25em'}}>
      <div className='pagination__showing'>
        <p className='xs:text-sm'>Showing {page.length} of {data.length} entries</p>
      </div>
      <div className='xs:mt-4 pagination__controls flex items-center'>
        <p className='xs:text-sm md:ml-2'>View</p>
        <select
          className='ml-2 mr-10'
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
          style={{
            WebkitAppearance: 'none',
            backgroundImage: `url(${chevronDown})`,
            backgroundColor: '#fff',
            backgroundPosition: '85% center',
            backgroundSize: '.75em',
            backgroundRepeat: 'no-repeat',
            border: '1px solid #bfbfbf',
            borderRadius: '.5em',
            padding: '.25em 0 .25em .5em',
            width: 70,
            cursor: 'pointer'
          }}
        >
          {[5, 10, 20, 25].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        {/*<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
          */}
        <button onClick={() => {previousPage(); window.scrollTo(0,0);}} disabled={!canPreviousPage} className='px-3 border rounded-l-md py-1' style={{ borderColor: '#bfbfbf' }}>
          <FontAwesomeIcon icon={faCaretLeft} color='#296db0' size='lg' />
        </button>{' '}
        <button onClick={() => {nextPage(); window.scrollTo(0,0);}} disabled={!canNextPage} className='px-3 border rounded-r-md py-1' style={{ borderColor: '#bfbfbf' }}>
          <FontAwesomeIcon icon={faCaretRight} color='#296db0' size='lg' />
        </button>{' '}
        <span className='xs:text-sm mx-2'>
          Page{' '} 
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
           <input
            type="text"
            defaultValue={pageIndex + 1}
            className="appearance-none bg-white pl-3 border rounded py-1"
            onChange={e => {
              //window.scrollTo(0,0);
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
              //setPagecurrval(e.target.value ? Number(e.target.value)-1 : 0);
             //gotoPage(pagecurrval);
              //window.scrollTo(0,0);
            
            }}
            style={{ width: 100, borderColor: '#bfbfbf' }}
            onKeyPress={(e) =>{
                evnthndleEnterScrollUp(e);
                
            }}
            //onClick={() => {
                    //window.scrollTo(0,0);
            //}}
            //value= {pagecurrval}
          />
        </span>{' '}
        {/* <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        */}
      </div>
    </div>
  )
}

export default Pagination;
