import { createContext } from 'react';

const SearchQuery = createContext({
  searchQuery: {
    label: '',
    value: '',
  },
  setSearchQuery: () => { }
});

SearchQuery.displayName = 'SearchQuery';

export default SearchQuery;
