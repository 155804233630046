import { GET_HISTORY } from '../api/endpoints';

/**
 * Returns the total views for the selected content
 * @param {string} nid - Node ID
 */
const getViews = async (nid) => {
  const { csrf_token } = JSON.parse(localStorage.getItem('login_response'));

  try {
    const res = await fetch(`${GET_HISTORY}/${nid}`, {
      headers: {
        'X-CSRF-Token': csrf_token,
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': `allow-from ${process.env.REACT_APP_DOMAIN_URL}`,
        'Content-Type': 'application/json',
      },
    });
    const json = await res.json();

    return json;
  } catch (error) {
    throw new Error(error);
  }

}

export default getViews;
