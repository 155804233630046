import Toastr from 'toastr';
import { API_URL, NODES } from '../api/endpoints';

/**
 * Returns the node id data
 * @param {string} content_type - Entity type. e.g. "Copy block"
 * @param {string} uuid - node id (article id)
 * @param {string} include - Fields to include. e.g. "field_copy_type". Add comma to include more fields.
 * @param {string} lang - Content language
 */
const getContent = async (content_type, uuid, include, lang) => {
  const { csrf_token } = JSON.parse(localStorage.getItem('login_response'));

  if (lang === 'en') {
    try {
      const res = await fetch(`${NODES}/${content_type}/${uuid}${include && '?include=' + include}`, {
        headers: {
          'X-CSRF-Token': csrf_token,
          'X-Content-Type-Options': 'nosniff',
          'X-Frame-Options': `allow-from ${process.env.REACT_APP_DOMAIN_URL}`,
          'Content-Type': 'application/json',
        },
      });
      const json = await res.json();
  
      if (res.status !== 200) {
        Toastr.error(`Error getting the content. Reason: ${res.statusText}`, null, {
          positionClass: 'toast-bottom-right',
        });
      }
      return json;
    } catch (error) {
      throw new Error(error);
    }
  } else {
    try {
      const res = await fetch(`${API_URL}/${lang}/jsonapi/node/copy_block/${uuid}?include=${include}`, {
        headers: {
          'X-CSRF-Token': csrf_token,
          'X-Content-Type-Options': 'nosniff',
          'X-Frame-Options': `allow-from ${process.env.REACT_APP_DOMAIN_URL}`,
          'Content-Type': 'application/json',
        },
      });
      const json = await res.json();

      if (res.status !== 200) {
        Toastr.error(`Error getting the content. Reason: ${res.statusText}`, null, {
          positionClass: 'toast-bottom-right',
        });
      }
  
      return json;
    } catch (error) {
      throw new Error(error);
    }
  }

}

export default getContent;
