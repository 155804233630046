import { createContext } from 'react';

const SearchFilter = createContext({
  filters: {
    taxonomies: {
      audience: [],
      categories: [],
      copy_type: [],
      proposed_usage: [],
      status: [],
      langcode: [],
      translations: [],
    },
    selectedFilters: [],
    selectedLanguages: [],
    resultTaxonomies: {
      audience: [],
      categories: [],
      copy_type: [],
      proposed_usage: [],
      status: [],
      translations: [],
    },
  },
  setFilters: (filters) => { }
});

SearchFilter.displayName = 'SearchFilter';

export default SearchFilter;
