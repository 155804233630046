import { TRANSLATIONS } from '../api/endpoints';

/**
 * Returns translations for article
 * @param {string} nid - node id
 */
const getNodeTranslations = async (nid) => {
  const { csrf_token } = JSON.parse(localStorage.getItem('login_response'));

  try {
      const res = await fetch(`${TRANSLATIONS}/${nid}`, {
        headers: {
          'X-CSRF-Token': csrf_token,
          'X-Content-Type-Options': 'nosniff',
          'X-Frame-Options': `allow-from ${process.env.REACT_APP_DOMAIN_URL}`,
          'Content-Type': 'application/json',
        },
      });
  
      return await res.json();
  } catch (error) {
    throw new Error(error);
  }

}

export default getNodeTranslations;
