import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => (
  <footer className={styles.footer}>
    <div className='flex justify-center'>
      <div className='container mx-auto xs:px-8 md:w-8/12 lg:w-full xl:w-8/12'>
        <p className='text-sm text-center text-white pt-4 pb-4'>
          <i>All of Us</i> and the <i>All of Us</i> logo are registered service marks of the U.S. Department of Health and Human Services.
          <br/>
          <a href="mailto: cr.support@allofuscomms.org">cr.support@allofuscomms.org</a>
          <br/>
          Copyright &copy; {new Date().getFullYear()} <a href='https://www.allofuscomms.org/terms' target="_blank" className='hover:underline'>Terms & Conditions</a>
        </p>
      </div>
    </div>
  </footer>
)

export default Footer;
