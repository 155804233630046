import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import styles from './Favorite.module.scss'

const Favorite = ({ onClick }) => (
  <FontAwesomeIcon
    icon={faHeart}
    // icon={faStarSolid}
    // color = '#45A575'
    size='lg'
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    title='Remove from Favorites'
    className={styles.heart + ' transition-all duration-500 ease-in-out'} 
    />
)

export default Favorite;
