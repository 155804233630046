import React, { useState } from 'react';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Page } from 'react-pdf';
import Modal from 'react-modal';
import { Magnifier } from 'react-image-magnifiers';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

const PDF = ({ pdf }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [renderedImage, setRenderedImage] = useState();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }


  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal(e){
    e.stopPropagation();
    setIsOpen(false);
  }

  const onDocumentRender = () => {
    const importPDFSVG = document.querySelector('.pdf-document canvas');
    setRenderedImage(importPDFSVG.toDataURL());
  }

  return (
    <>
      <div className='pdf-document flex justify-center items-center flex-col'>
        {
          numPages > 1 &&
          <div className='flex items-center mb-5'>
            <button
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              className='p-2 border-l border-r border-t border-b rounded-tl-md rounded-bl-md border-gray-300'
            >
              <FontAwesomeIcon icon={faChevronCircleLeft} size='lg' color='#555' />
              </button>
            <p className='border-t border-b border-gray-300 py-2 px-4 text-gray-500 cursor-default'>
              {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
            </p>
            <button
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              className='p-2 border-l border-r border-t border-b rounded-tr-md rounded-br-md border-gray-300'
            >
              <FontAwesomeIcon icon={faChevronCircleRight} size='lg' color='#555' />
              </button>

          </div>
        }
        <div onClick={openModal} className='cursor-pointer'>
          <Document
            file={pdf}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} width={window.innerWidth > 1280 ? 1400 : 300} onRenderSuccess={onDocumentRender} />
          </Document>
        </div>
      </div>
      {
        modalIsOpen &&
          <Modal
          ariaHideApp={false}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          >
            <Magnifier 
              imageSrc={renderedImage}
            />
          </Modal>
      }
    </>
  );
}

export default PDF;
