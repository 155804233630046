import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import oidc from "./config";
import { API_URL } from "./api/endpoints";
import getLanguages from "./utils/getLanguages";
import SearchQuery from "./contexts/SearchQuery";
import SiteLanguages from "./contexts/SiteLanguages";
import SearchFilter from "./contexts/SearchFilter";
import UpdateFilters from "./contexts/UpdateFilters";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import LoginLanding from "./pages/loginLanding/LoginLanding";
import GetStarted from "./pages/get-started/GetStarted";
import Favorites from "./pages/favorites/Favorites";
import SharedContent from "./pages/shared-content/SharedContent";
import SearchResults from "./pages/search-results/SearchResults";
import "./App.css";
import SearchOptions from "./contexts/SearchOptions";

function App() {
  const [filters, setFilters] = useState({
    taxonomies: {
      audience: [],
      categories: [],
      copy_type: [],
      proposed_usage: [],
      status: [],
      langcode: [],
      translations: [],
    },
    selectedFilters: [],
    selectedLanguages: [],
    resultTaxonomies: {
      audience: [],
      categories: [],
      copy_type: [],
      proposed_usage: [],
      status: [],
      translations: [],
    },
  });
  const [languages, setLanguages] = useState({});
  const [searchQuery, setSearchQuery] = useState({ label: "", value: "" });
  const [updateFilters, setUpdateFilters] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("login_response") === null) {
      let loginUrl = `${API_URL}/user/login?_format=json`;
      if (loginUrl.includes("undefined")) {
        loginUrl = loginUrl.replace("undefined/", "");
      }

      fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: `${process.env.REACT_APP_API_USER}`,
          pass: `${process.env.REACT_APP_API_USER_PASS}`,
        }),
      })
        .then((data) => data.json())
        .then((json) =>
          localStorage.setItem("login_response", JSON.stringify(json))
        );
    }

    // Get site languages
    getLanguages()
      .then((res) => setLanguages(res))
      .catch((error) => new Error(error));
  }, []);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const oktaAuth = new OktaAuth(oidc);

  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <SiteLanguages.Provider value={{ languages, setLanguages }}>
            <SearchOptions.Provider
              value={{ optionsVisible, setOptionsVisible }}
            >
              <Route exact path="/" component={LoginLanding} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route path="/login/callback" component={LoginCallback} />
              <SearchQuery.Provider value={{ searchQuery, setSearchQuery }}>
                <SearchFilter.Provider value={{ filters, setFilters }}>
                  <UpdateFilters.Provider
                    value={{ updateFilters, setUpdateFilters }}
                  >
                    <SecureRoute
                      exact
                      path="/search-results"
                      component={SearchResults}
                    />
                  </UpdateFilters.Provider>
                  <SecureRoute
                    exact
                    path="/content"
                    component={SharedContent}
                  />
                  <SecureRoute
                    exact
                    path="/get-started"
                    component={GetStarted}
                  />
                  <SecureRoute exact path="/favorites" component={Favorites} />
                </SearchFilter.Provider>
              </SearchQuery.Provider>
            </SearchOptions.Provider>
          </SiteLanguages.Provider>
        </Switch>
      </Security>
    </div>
  );
}

export default App;
