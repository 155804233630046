import React, { useEffect, useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faTimesCircle, faVial, faWrench, faLaptop } from '@fortawesome/free-solid-svg-icons';
import Toastr from 'toastr';
import Carousel from '../carousel/Carousel';
import Favorite from '../favorites/Favorite';
import UnFavorite from '../favorites/UnFavorite';
import getContent from '../../utils/getContent';
import getNodeTranslations from '../../utils/getNodeTranslations';
import { postFavoriteAdd, postFavoriteRemove } from '../../utils/postFavorite';
import getViews from '../../utils/getViews';
import postViews from '../../utils/postViews';
import styles from './DetailsPanel.module.scss';
import 'toastr/build/toastr.css';
import LoadingSVG from '../../images/spinner.svg';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import getLanguages from '../../utils/getLanguages';

const DetailsPanel =({ 
    favoritesList,
    setIsFavorited,
    setFavId,
    visible, 
    favorited, 
    favoriteId, 
    id, 
    nid, 
    uuid, 
    language, 
    setVisibleDetailsPanel, 
    updateResultsTable,
 }) => {
 
  const [favoritedState, setFavoritedState] = useState(false);
//   const [componentFavoriteId, setComponentFavoriteId] = useState(0);
  const [componentFavoriteId, setComponentFavoriteId] = useState(null);
//   const[changeTranslationCount, setChangeTranslationCount] = useState(0);
  

  const include = 'field_keywords_fake,field_copy_type,field_status,field_categories,field_proposed_usage,field_audience,field_related_asset_id,field_visual_assets.field_media_document,field_visual_assets.field_media_image';

  const [data, setData] = useState({
    created: new Date(),
    changed: new Date(),
    status: '',
    view_count: 0,
    translations: {},
    categories: [],
    proposed_usage: [],
    audience: [],
    keywords_fake: [],
    done: false,
    language: '',
    //handleChangeTranslationCount: 0,
  });

  useEffect(() => {
    (async () => {
      try {
        if (visible) {
          document.body.style.overflow = 'hidden';
 
          if (favorited === true) {
            setFavoritedState(true);
          }

          // Get relative data for the panel
          const res = await getContent('copy_block', uuid, include, language);

          // Get taxonomies
          const [{ attributes }] = res.included.filter(({ type }) => type === 'taxonomy_term--copy_type');
          const status = res.included.filter(({ type }) => type === 'taxonomy_term--status');
          const categories = res.included.filter(({ type }) => type === 'taxonomy_term--categories');
          const proposed_usage = res.included.filter(({ type }) => type === 'taxonomy_term--proposed_usage');
          const audience = res.included.filter(({ type }) => type === 'taxonomy_term--audience');
          const keywords_fake = res.included.filter(({ type }) => type === 'taxonomy_term--keywords');
          const related_assets = res.included.filter(({ type }) => type === 'taxonomy_term--related_asset_ids');
          const images = res.included.filter(({ type }) => type === 'file--file');


          // Post view
          const userId = localStorage.getItem('user_id');
          await postViews(nid, userId);
          // Get views
          const view = await getViews(nid);

          getNodeTranslations(nid)
            .then((translations) => {
              // Get site languages
              getLanguages().then((siteLanguages) => {
                setData({
                  copy_type: attributes.name,
                  id: res.data.attributes.field_id,
                  status: status[0]?.attributes.name,
                  conditions: res.data.attributes.field_conditions,
                  copy_field_block: res.data.attributes.field_copy_block?.value,
                  view_count: view,
                  related_assets: related_assets?.map(({ attributes }) => attributes.name),
                  related_assets_url: related_assets?.map(({ attributes }) => attributes.field_related_asseturl),
                  created: res.data.attributes.created,
                  changed: res.data.attributes.changed,
                  defaultLanguage: res.data.attributes.langcode,
                  language: res.data.attributes.langcode,
                  translations,
                  source: res.data.attributes.field_source,
                  aplink: res.data.attributes.field_aplink,
                  keywords: res.data.attributes?.field_keywords,
                  keywords_fake: keywords_fake?.map(({ attributes }) => attributes.name),
                  categories: categories?.map(({ attributes }) => attributes.name),
                  proposed_usage: proposed_usage?.map(({ attributes }) => attributes.name),
                  audience: audience?.map(({ attributes }) => attributes.name),
                  images,
                  notes: res.data.attributes.field_notes?.value,
                  changelog: res.data.attributes.field_change_log?.value,
                  languages: siteLanguages,
                  done: true,
                })
              })
                .catch((error) => new Error(error));
            })
            .catch((error) => new Error(error));
        }

      } catch (error) {
        throw new Error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, visible])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setVisibleDetailsPanel(false);
        document.body.style.overflow = 'auto';
      }
    };

    // Attach the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setVisibleDetailsPanel]);

  async function handleFavorites(state) {
    try {
      if (state) {
        const res = await postFavoriteRemove(favoriteId !== undefined ? favoriteId : componentFavoriteId);
        //console.log(res);
        if (res.status === 200) {
          Toastr.success(await res.text(), null, {
            positionClass: 'toast-bottom-right',
          });
          setFavoritedState(false);
          updateResultsTable(true);
        }
      } else {
        const res = await postFavoriteAdd(nid, data.language);
        //console.log(res);
        if (res.status === 200) {
          const { id, type, message } = await res.json();
          //console.log(type);
          //console.log(id);
          //console.log(message);
          if (type === 'success') {
            Toastr.success(message, null, {
              positionClass: 'toast-bottom-right',
            });
            setComponentFavoriteId(id);
            setFavId(id);
            setFavoritedState(true);
            updateResultsTable(true);
          } else {
            Toastr.error(message, null, {
              positionClass: 'toast-bottom-right',
            });
          }
        }
      }
    } catch (error) {
      Toastr.error(error.message, null, {
        positionClass: 'toast-bottom-right',
      })
      throw new Error(error);
    }
  }
 
  
   const handleChangeTranslation = async (lang) => {
    try {
      const res = await getContent('copy_block', uuid, include, lang);
      const images = res.included.filter(({ type }) => type === 'file--file');
      const keywords_fake = res.included.filter(({ type }) => type === 'taxonomy_term--keywords');

      //when switching  between different language copy block versions
      //control structure executed sequentially + only once -> decision making -> if -> 1 condition statemewnt + 1 conditional statements
       
      setData((prevState) => ({
        ...prevState,
        id: res.data.attributes.field_id,
        language: res.data.attributes.langcode,
        copy_field_block: res.data.attributes.field_copy_block?.value,
        source: res.data.attributes.field_source,
        aplink: res.data.attributes.field_aplink,
        conditions: res.data.attributes.field_conditions,
        keywords: res.data.attributes.field_keywords,
        keywords_fake: keywords_fake?.map(({ attributes }) => attributes.name),
        images,
        notes: res.data.attributes.field_notes?.value,
        changelog: res.data.attributes.field_change_log?.value,
        status: res.included.filter(({ type }) => type === 'taxonomy_term--status')[0]?.attributes.name,
        created: res.data.attributes.created,
        changed: res.data.attributes.changed,
        //handleChangeTranslationCount : prevState.handleChangeTranslationCount + 1,
    }));

    setFavId(favoritesList.filter(item => res.data.attributes.field_id === item.id)[0]?.fid);
    setIsFavorited((favoritesList.filter(item => res.data.attributes.field_id === item.id)[0]?true:false))
    setFavoritedState((favoritesList.filter(item => res.data.attributes.field_id === item.id)[0]?true:false))
    updateResultsTable();
      //control structure  executed sequentially + only once -> decisionmaking ->if  -> 1 condition + 1 conditional  statement
      //if changeTranslationCoun == 1
      //   if (changeTranslationCount == 1)
      //   {
        //check  if favoritedState == false
        //control structure  executed sequentially + only once -> decisionmaking ->if  -> 1 condition + 1 conditional  statement
        // if (favoritedState == false)
        // {
        //   setFavoritedState(true);
        // }

      //     setChangeTranslationCount(0);
      //   }
    } catch (error) {
      throw new Error(error);
    }

    
  }


 const handleClearState = () => {
    setData({
      copy_type: '',
      id: '',
      status: '',
      conditions: [],
      copy_field_block: '',
      view_count: 0,
      related_assets: [],
      created: new Date(),
      changed: new Date(),
      language: '',
      translations: {},
      source: '',
      keywords: [],
      keywords_fake: [],
      categories: [],
      proposed_usage: [],
      audience: [],
      images: [],
      notes: '',
      changelog: '',
      done: false,
      //handleChangeTranslationCount : 0,
    });
    setFavoritedState(false);
    // setChangeTranslationCount(0);
  }
  

  const Loading = () => (
    <div className='flex items-center justify-center' style={{ height: 'calc(100vh - 88px)' }}>
      <img src={LoadingSVG} alt='loading' />
    </div>
  )

  const StatusField = () => {
    if (data.status === 'IRB Approved No Conditions') {
      return (
        <FontAwesomeIcon className='mr-2'
          icon={faCheck}
          color='#10811e' />
      )
    } else if (data.status === 'IRB Approved With Conditions' || data.status === 'NIH Approved') {
      return (
        <FontAwesomeIcon className='mr-2'
          icon={faExclamationCircle}
          color='#f3a639' />
      )
    } else if (data.status === 'For Test Only') {
        return (
          <FontAwesomeIcon className='mr-2'
            icon={faWrench}
            color='#a27db7' />
        )
    } else if (data.status === 'For Researchers Only') {
        return (
          <FontAwesomeIcon className='mr-2'
            icon={faLaptop}
            color='#5eaee0' />
        )
    } else {
      return (
        <FontAwesomeIcon className='mr-2'
        icon={faTimesCircle}
        color='#cd1a2a' />
      )
    }
  }
 
  
//   const Actions = () => {
//       if (favoritedState)
//       {
//         return (
//             <Favorite onClick={() => handleFavorites(true)}/>
//         )
//       }
//       return (
//         <UnFavorite onClick={() => handleFavorites(false)}/>
//       )
//     }

   


  return (
    <div className={`${styles.details__panel} ${visible ? 'visible' : 'hidden'} xs:w-full md:w-full lg:w-10/12 xl:w-9/12 min-h-full bg-white`}>
      <div className='flex justify-end py-5 px-2 shadow-md'>
        <div className='flex'>
          <div className='details__panel__favorite flex justify-center items-center flex-col mr-8'>
            {
              favoritedState
                ? <Favorite onClick={() => handleFavorites(true)} />
                : <UnFavorite onClick={() => handleFavorites(false)} />
            }
            <p onClick={() => favoritedState ? handleFavorites(true) : handleFavorites(false)} className={favoritedState ? styles.details__panel__unfav_toggle + ' xs:text-sm md:text-base' : styles.details__panel__fav_toggle + ' xs:text-sm md:text-base'}>{favoritedState ? 'Remove as Favorite' : 'Mark as Favorite'}</p>
          </div>
          <div className='details__panel__views flex justify-center items-center flex-col mr-8 hidden'>
            <FontAwesomeIcon icon={faEye} color='#7ac79b' size='lg' />
            <p className='xs:text-sm md:text-base'>{data.view_count} views</p>
          </div>
        </div>
        <div className={styles.details__panel__close + ' flex items-center'}>
          <button
            onClick={() => {
            //   setVisibleDetailsPanel(!visible);
              setVisibleDetailsPanel(false);
              document.body.style.overflow = 'auto';
            }}
            className='flex items-center justify-center'>
            <span className='flex items-center'>
              <svg width="28" height="28" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" onClick={handleClearState} >
                <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
                <path d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0" />
                <path d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0" />
              </svg>
            </span>
          </button>
        </div>
      </div>
      {
        !data.done ? <Loading />
          :
          <div className='container-fluid flex flex-wrap justify-evenly mt-8'>
            <div className='xs:w-full md:w-full lg:w-6/12 pr-4 pl-4'>
              <div className='details__panel__copy mb-8'>
                <h3 className='mb-4 pb-2 border-b xs:text-sm md:text-xl'>Copy</h3>
                <p className={styles.details__panel__copy + ' break-words'} dangerouslySetInnerHTML={{ __html: data.copy_field_block }} />
              </div>
              <div className='details__panel__sample__assets'>
                <h3 className='mb-4 pb-2 border-b xs:text-sm md:text-xl'>Visual Reference</h3>
                <Carousel images={data.images} />
                {data.images.length > 0 && data.aplink &&
                    <a className={styles.details__panel__ap_link} target="_blank" rel='noreferrer' href={data.aplink}>View on Asset Portal &gt;</a>
                }
              </div>
            </div>
            <div className='xs:w-full md:w-full lg:w-6/12 pr-4 pl-4 mb-10'>
              <div className={styles.details__panel__details + ' break-words'}>
                <h3 className='xs:mt-4 mb-4 pb-2 border-b xs:text-sm md:text-xl'>Details</h3>
                <ul>
                  <li className='flex justify-between pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>ID</p>
                    <p className='w-9/12 xs:pl-2'>{data.id}</p>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Status</p>
                    <div className='flex w-9/12 items-center ml-4 xs:ml-2'>
                      <StatusField />
                      <p>{data.status}</p>
                    </div>
                  </li>
                  {
                    data.conditions && data.status === 'For Researchers Only' ?
                    <li style={{ backgroundColor: "#6baae220"}} className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                      <p className='w-2/12 xs:w-4/12 font-bold'>Status Details</p>
                      <p className='w-9/12 xs:w-8/12'>{data.conditions}</p>
                    </li>
                    :
                    data.conditions && data.status &&
                    <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                      <p className='w-2/12 xs:w-4/12 font-bold'>Status Details</p>
                      <p className='w-9/12 xs:w-8/12'>{data.conditions}</p>
                    </li>
                  }
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Notes</p>
                    <div className='flex w-9/12'>
                      <p className='w-8/15 break-words xs:pl-2' dangerouslySetInnerHTML={{ __html: data.notes }} />
                    </div>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Source</p>
                    <div className='flex w-9/12 xs:pl-2'>
                      <p className='break-words'>{data.source}</p>
                    </div>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Language</p>
                    <p className='w-9/12 xs:pl-2'>{data.languages[data.language]}</p>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Translation(s)</p>
                    <div className='w-9/12 xs:w-8/12'>
                      <ul className='list-disc ml-4 xs:pl-2'>
                        {/* eslint-disable-next-line array-callback-return */}
                        {Object.keys(data?.translations).map((lang, index) => {
                          // If content "Language" is the only translation
                          if (lang === data.language && Object.keys(data?.translations).length === 1) {
                            return (
                              <li key={index} className='-ml-4 list-none'>
                                <p>No translation(s)</p>
                              </li>
                            )
                          }

                          // If translations are available, remove the translation for the content "Language"
                          if (lang !== data.language) {
                            return (
                              <li key={index} data-lang={lang}>
                                <button  className={ styles.details__panel__language_toggle } onClick={() => handleChangeTranslation(lang)}>{data.languages[lang]}</button>
                              </li>
                            )
                          }
                        })}
                      </ul>
                    </div>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Copy type</p>
                    <p className='w-9/12 xs:pl-2'>{data.copy_type}</p>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Category</p>
                    <p className='w-9/12 xs:pl-2'>
                      {data.categories.join(', ')}
                    </p>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Audience</p>
                    <p className='w-9/12 break-words xs:pl-2'>
                      {data.audience.join(', ')}
                    </p>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Proposed usage</p>
                    <p className='w-9/12 break-words xs:pl-2'>
                      {data.proposed_usage.join(', ')}
                    </p>
                  </li>

                  {/* Commenting out the Related Assets row */}

                  {/* <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Related asset</p>
                    <div className='flex w-9/12 xs:pl-2'>
                      {data.related_assets.map((text, index) =>
                        <a
                          key={index}
                          href={data.related_assets_url[index]}
                          className='w-8/12 break-words'
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ color: '#216FB4' }}>
                          {text}
                        </a>)}
                    </div>
                  </li> */}
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Keywords</p>
                    <div className='flex flex-wrap w-9/12 xs:pl-2'>
                        {/* {data.keywords?.sort((function (a, b) { */}
                        {data.keywords_fake?.sort((function (a, b) {
                            return a.localeCompare(b);
                        })).map((text, index) => <a key={index} href={`/search-results?search=${text}`}>
                            <p className={styles.details__panel__language_toggle + ' px-4 py-1 rounded-full border border-gray-400 mr-2 mb-2'} data-bold={text}>{text}</p>
                        </a>)}
                    </div>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Date added</p>
                    <time className='w-9/12 xs:pl-2'
                      dateTime={data.created}>{
                        new Intl.DateTimeFormat('en-US').format(new Date(data?.created))
                      }</time>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Last modified</p>
                    <time className='w-9/12 xs:pl-2'
                      dateTime={data.changed}>{
                        new Intl.DateTimeFormat('en-US').format(new Date(data?.changed))
                      }</time>
                  </li>
                  <li className='flex justify-between pt-4 pb-4 px-2 border-b border-gray-400'>
                    <p className='w-2/12 xs:w-4/12 font-bold'>Change log</p>
                    <div className='flex w-9/12'>
                      <p className='w-8/15 break-words xs:pl-2' dangerouslySetInnerHTML={{ __html: data.changelog }} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      }
    </div>
  )
};

export default DetailsPanel;
