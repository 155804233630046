import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
// import Search from '../../components/search/Search';
import Table from './table/Table';
import { API_URL } from '../../api/endpoints';
import styles from './Favorites.module.scss';
import LoadingSVG from '../../images/spinner.svg';

const Favorites = () => {
  const [favoritesList, setFavoritesList] = useState({ data: [], isLoading: true });
  const [searchedList, setSearchedList] = useState({ data: [], isLoading: true });
  const [updateFavorites, setUpdateFavorites] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
//   const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('user_id') === null && localStorage.getItem('okta-token-storage')) {
        const { idToken } = JSON.parse(localStorage.getItem('okta-token-storage'));
      fetch(`${API_URL}/api/rest-endpoint/get/user/${idToken?.claims?.email}`)
        .then((res) => res.json())
        .then((id) => localStorage.setItem('user_id', id))
        .catch((error) => new Error(error));
    }
  }, []);

  useEffect(() => {
    if (updateFavorites) {
      const userId = localStorage.getItem('user_id');
      fetch(`${API_URL}/api/rest-endpoint/favorite/${userId}/list`)
        .then((res) => res.json())
        .then((json) => {
          setFavoritesList({ data: json, isLoading: false });
          setUpdateFavorites(false);
          if (json.length === 0) {
            document.body.style.overflow = "auto";
          }
        })
        .catch((error) => console.error(error));
    }
  }, [updateFavorites]);

  useEffect(() => {
    if (searchTerm !== '') {
      let searchedData = [];
      let favData = JSON.parse(JSON.stringify(favoritesList.data));
      favData.forEach((item, index) => {
        delete item.nid;
        delete item.uuid;
        delete item.fid;
        delete item.date;
        delete item.langcode;
        delete item.status;
        let stringified = JSON.stringify(item).toLowerCase();
        stringified.includes(searchTerm) ? 
          searchedData.push(favoritesList.data[index]) : console.log("nope");
      });
      setSearchedList({ data: searchedData, isLoading: false });
    } else {
      setSearchedList({ data: [], isLoading: false });
    }
  }, [searchTerm, favoritesList.data]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
// 
//     const input = e.target.search.value.toLowerCase();
// 
//     history.push(`/search-results?search=${input}`);
//   }

  const Loading = () => (
    <div className='flex items-center justify-center' style={{ minHeight: 'calc(100vh - 530px)' }}>
      <img src={LoadingSVG} alt='loading' />
    </div>
  )

  const handleChange = (e) => {
      setSearchTerm(e.target.value.toLowerCase())
  }        

  return (
    <>
      <Header />
      <main className={styles.favorites + ' xs:my-4 md:my-12'}>
        <div className='xl:container md:px-12 lg:px-19 lg:mx-auto md:w-full xs:px-8 lg:w-full xl:w-full'>
          <h2 className='xs:text-lg text-4xl font-bold my-2' style={{ color: '#262262' }}>Your Favorites</h2>
          <p className='xs:text-sm text-2xl'><span style={{ color: '#f0738c' }}>{favoritesList.data.length}</span> items saved. Use the search bar below to find specific copy in your Favorites.</p>
          <div className='flex justify-center my-8'>
            <div className='favorites__search xs:w-full w-5/12'>
              <div className={`${styles.search__input} flex items-center px-4 w-full`}>
                <input type="text" className={`flex items-center px-4 w-full`}
                style={{ padding: '.5em 0', width: '100%', appearance: 'none', background: 'transparent' }}
                placeholder={`Search Your Favorites`}
                onChange={handleChange} />
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#9c9c9c" className="bi bi-search" viewBox="0 0 16 16">
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              </div>
              {/* <form onSubmit={handleSubmit}>
                <Search />
              </form> */}
              <p className='text-center xs:text-sm text-xl mt-4'><a className=' transition-color duration-300 hover:text-green-600' href="/home">Return to home page</a></p>
            </div>
          </div>
          <div className={styles.favorites__table}>
            {
              favoritesList.isLoading
              ? <Loading />
              : searchTerm.length > 0  
              ? <Table favoritesList={searchedList.data} setUpdateFavorites={setUpdateFavorites} />
              : <Table favoritesList={favoritesList.data} setUpdateFavorites={setUpdateFavorites} />
            }
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default Favorites;
