import React from 'react';
import Header from '../../components/header/Header';
import Hero from './hero/Hero';

const GetStarted = () => (
  <>
    <Header />
    <div className='container mx-auto xs:px-8 md:w-8/12 lg:w-full xl:w-8/12'>
      <Hero />
    </div>
  </>
)

export default GetStarted;