import { POST_FAVORITE_ADD, POST_FAVORITE_REMOVE } from '../api/endpoints';

/**
 * Adds an article as a favorite for the current user
 * @param {string} nid - node id
 */
export const postFavoriteAdd = async (nid, langcode = 'en') => {
  const userId = localStorage.getItem('user_id');
  const { csrf_token } = JSON.parse(localStorage.getItem('login_response'));

  try {
      const res = await fetch(`${POST_FAVORITE_ADD}/${nid}/${userId}/${langcode}`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrf_token,
          'X-Content-Type-Options': 'nosniff',
          'X-Frame-Options': `allow-from ${process.env.REACT_APP_DOMAIN_URL}`,
        },
      });
      const msg = await res;
  
      return msg;
  } catch (error) {
    throw new Error(error);
  }

}

/**
 * Remove an article as a favorite for the current user
 * @param {string} id - favorite id, e.g. "1"
 */
export const postFavoriteRemove = async (id) => {
  const { csrf_token } = JSON.parse(localStorage.getItem('login_response'));

  try {
      const res = await fetch(`${POST_FAVORITE_REMOVE}/${id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': csrf_token,
          'X-Content-Type-Options': 'nosniff',
          'X-Frame-Options': `allow-from ${process.env.REACT_APP_DOMAIN_URL}`,
        },
      });
      const msg = await res;
  
      return msg;
  } catch (error) {
    throw new Error(error);
  }

}