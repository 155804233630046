import React, { useEffect, useContext } from 'react';
import Tippy from '@tippyjs/react';
import SiteLanguages from '../../../contexts/SiteLanguages';
import SearchFilter from '../../../contexts/SearchFilter';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import styles from './Filter.module.scss';
import ArrowDown from '../../../images/icons/feather-chevron-down.svg'
import UpdateFilters from '../../../contexts/UpdateFilters';

const Popup = ({ filter, value }) => {
  const { languages } = useContext(SiteLanguages);
  const { filters, setFilters } = useContext(SearchFilter);
  const { setUpdateFilters } = useContext(UpdateFilters);

  useEffect(() => {
    setTimeout(() => {
      if (value) {
        if (value.length > 0) {
          let arr = value.join(', ').split(', ');
  
          const counts = arr.join(',').split(',').reduce((acc, value) => ({
            ...acc,
            [value]: (acc[value] || 0) + 1
          }), {});
  
  
          // eslint-disable-next-line array-callback-return
          Object.entries(counts).map(([key, value]) => {
            let filterQuery = filter === 'translations' ? 'langcode' : filter;
            if (new URLSearchParams(window.location.search).get(filterQuery)) {
              let queryParams = new URLSearchParams(window.location.search).get(filterQuery).split(',');
              let isPresent = queryParams.some((obj) => encodeURIComponent(obj) === encodeURIComponent(key.replace(/&amp;/g, "&")).toLowerCase());
              if (isPresent) {
                setFilters((prevState) => ({
                  ...prevState,
                  selectedFilters: !prevState.selectedFilters.some((obj) => obj.label === key) ?
                    [...prevState.selectedFilters, { key: filterQuery, value: value, label: key, encoded: encodeURIComponent(key.replace(/&amp;/g, "&")).toLowerCase() }] : [{ key: filterQuery, value: value, label: key, encoded: encodeURIComponent(key.replace(/&amp;/g, "&")).toLowerCase() }],
                }))
              }
            }
          });
        }
      }
    }, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Content is available
  if (value) {
    if (value.length > 0) {
      let arr = value.join(', ').split(', ');

      const counts = arr.join(',').split(',').reduce((acc, value) => ({
        ...acc,
        [value]: (acc[value] || 0) + 1
      }), {});

      return (
        <ul className={styles.tooltip}>
          {
            Object.entries(counts).map(([key, value]) => {
              let taxonmyFilter = filter !== 'translations' ? filter : 'langcode';

              return (
                <li key={key} className='flex px-2 py-1 list-none' style={{ cursor: 'pointer' }} onClick={(e) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    taxonomies: {
                      ...prevState?.taxonomies,
                      [taxonmyFilter]: !prevState.taxonomies[taxonmyFilter].includes(encodeURIComponent(key.replace(/&amp;/g, "&")).toLowerCase())
                        ? [...prevState.taxonomies[taxonmyFilter], encodeURIComponent(key.replace(/&amp;/g, "&")).toLowerCase()]
                        : prevState.taxonomies[taxonmyFilter].filter(item => item !== encodeURIComponent(key.replace(/&amp;/g, "&")).toLowerCase())
                    },
                    selectedFilters: !prevState.selectedFilters.some((obj) => obj.label === key) ?
                      [...prevState.selectedFilters, { key: taxonmyFilter, value: value, label: key, encoded: encodeURIComponent(key.replace(/&amp;/g, "&")).toLowerCase() }] : prevState.selectedFilters.filter(((obj) => obj.label !== key)),
                  }))
                  setUpdateFilters(true);
                }}>
                  <input type='checkbox'
                    checked={filters?.taxonomies[taxonmyFilter]?.includes(encodeURIComponent(key.replace(/&amp;/g, "&")).toLowerCase())}
                    name={taxonmyFilter}
                    data-taxonomy={encodeURIComponent(key.replace(/&amp;/g, "&"))}
                    className='mr-2'
                    readOnly
                  />
                  <span className='mr-2' dangerouslySetInnerHTML={{ __html: filter !== 'translations' ? key : languages[key] }} />
                  <span>({value})</span>
                </li>
              )
            })
          }
        </ul>
      )
    }
  }

  return (
    <ul className='pl-0'>
      <p>No content</p>
    </ul>
  )

}

const Filter = ({ width, justify }) => {
  const { filters } = useContext(SearchFilter);

  const handleTrigger = (e) => {
    e.reference.classList.add(styles.search__list__active);
  }

  const handleHide = (e) => {
    e.reference.classList.remove(styles.search__list__active);
  }

  return (
    <div className={styles.search__filter + ' flex flex-wrap justify-center ' + width}>
      {
        <>
          <span className='flex items-center mr-2 xs:text-sm xs:w-full'>
            <i>Filter by:</i>
          </span>
          <ul className={styles.search__list + ' flex flex-wrap md:justify-' + justify}>
            {
              Object.entries(filters.resultTaxonomies)
                .map(([key, value], index) => {
                  // langcode is unused in filter chips
                  if(key == "langcode"){
                    return null
                  }
                  if(key=="translations"){
                  var labelKey = "languages";
                  }
                  return (
                    <div key={key}>
                      <Tippy
                        content={<Popup filter={key} value={value} />}
                        interactive
                        placement='bottom'
                        theme='light'
                        trigger='click'
                        onTrigger={handleTrigger}
                        onHide={handleHide}
                        className={styles.tippy}
                      >
                        <div className={styles.taxonomies + ' flex xs:pl-0 pr-4 pl-4 py-3 cursor-pointer items-center'} tabIndex={index}>
                          {
                            key !== 'langcode' && key != 'translations'
                            && <>
                              <li className='mr-2 capitalize transition-all duration-500 ease-in-out list-none'>
                                {key.replace('_', ' ')}
                              </li>
                              <img src={ArrowDown} loading='lazy' alt='arrow down' width={12} height={12} />
                            </>
    
                          }

                          {
                            key == 'translations'
                            && <>
                              <li className='mr-2 capitalize transition-all duration-500 ease-in-out list-none'>
                                {labelKey.replace('_', ' ')}
                              </li>
                              <img src={ArrowDown} loading='lazy' alt='arrow down' width={12} height={12} />
                            </>
                          }

                        </div>
                      </Tippy>
                    </div>
                  )
                })
            }
          </ul>
        </>
      }
    </div >
  )
}

export default Filter;
